import React, { Component } from "react";
import { Button, Card } from 'antd';
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//COMPONENTES
import { CardPremio } from "../../Widgets/Cards";

//MODALES
import ModalPremio from "./Tabs/ModalPremio"



// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	margin: `0 ${grid}px 0 0`,
	width: '250px',

	// change background colour if dragging
	background: isDragging ? "lightgreen" : "transparent",

	// styles we need to apply on draggables
	...draggableStyle
});



class App extends Component {


	constructor(props) {
		super(props);
		this.state = {
			items: [],
		};
		
	}

	componentDidUpdate(prevProps){
		if(this.props.premios?.length > 0 && prevProps.premios.length != this.props.premios.length){
			this.setState({items: this.props.premios.map(e=>({...e, id: e._id}))})
		}
	}

	onDragEnd = (result) => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(
			this.state.items,
			result.source.index,
			result.destination.index
		);

		this.setState({
			items
		});
	}

	/**
	 * @method onSave
	 * @description Añade o actualiza los valores del array de items
	 * */
	onSave = (values) => {
		let items = this.state.items
		if(!values.id){
			//Se añade una id temporal para que se pueda hacer drag, tambien nos sirve para identificar las imagenes que pertenecen al premio
			//ya que esta se añade a os nombre sde las imagenes, (se hace en onFinish de DrawerRifa)
			values.id = Math.floor(Math.random() * 1000000).toString(16);
			items.push(values)
		}else{
			let index = items.findIndex(e => e.id === values.id)
			if(index !== -1)
				items.splice(index,1,values)
		}
		this.setState({items})

	}


	/**
	 * @method onDelete
	 * @description Elimina un elemento del array de items
	 * */
	onDelete = (index) => {
		let items = [...this.state.items]
		if(index !== -1)
			items.splice(index,1)
		this.setState({items})

	}


	/**
	 * @method onEdit
	 * @description Elimina un elemento del array de items
	 * */
	onEdit = (item) => {
		this.setState({modal_visible: true, premio: item})

	}


	getListStyle = (isDraggingOver, itemsLength) => ({
		background: isDraggingOver ? "lightblue" : "lightgrey",
		display: "-webkit-box",
		padding: grid,
		width: this.state.items.length > 4 ? 'fit-content' : "100%",
		minHeight: '200px',
	});

	// Normally you would want to split things out into separate components.
	// But in this example everything is just done in one place for simplicity
	render() {
		return (
			<div style={{overflow: "auto"}}>
				<Button onClick={()=>this.setState({modal_visible: true})} className="mb-1"> Agregar </Button>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Droppable droppableId="droppable" direction="horizontal">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								style={this.getListStyle(snapshot.isDraggingOver, this.state.items.length)}
								{...provided.droppableProps}
							>
								{this.state.items.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style
												)}
											>
												<CardPremio 
													{...item} 
													index={index} 
													local={true}
													onEdit={this.onEdit}
													onDelete={this.onDelete}
												/>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<ModalPremio
					visible={this.state.modal_visible}
					onClose={()=>{
						this.setState({ modal_visible: false, premio: undefined})
					}}
					local={true}
					onSave={this.onSave}
					premio={this.state.premio}
				/>
			</div>
		);
	}
}

export default App