import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Row, Col, Drawer, Space, Button, Tabs, Form, message, Spin } from 'antd';
// import axios from "axios"
// import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined, LockOutlined, ShoppingOutlined, UploadOutlined } from '@ant-design/icons';
// import { AiOutlineMinusCircle } from "react-icons/ai";
import axios from "axios";

import InformacionGeneral from "./DrawerSorteo/InformacionGeneral";
import Descripciones from "./DrawerSorteo/Descripciones";
import ImagesForm from "./DrawerSorteo/ImagesForm";
import Premios from "./DrawerSorteo/Premios";
import { useForm } from "antd/lib/form/Form";
// import Ejemplo from "./DrawerSorteo/Ejemplo";

const { TabPane } = Tabs;


const DrawerSorteoForwadRefWrapper = forwardRef(function DrawerSorteo(props, ref) {

    const { sorteo_id, onClose } = props


    const [loading, setLoading] = useState(false)

    const [sorteo, setSorteo] = useState()
    const [sorteoId, setSorteoId] = useState(sorteo_id)

    const [tab, setTab] = useState('informacion-general')

    const [formInformacionGeneral] = Form.useForm();
    const [formDescripciones] = Form.useForm();
    const [formImages] = Form.useForm();

    const getForm = () => {
        switch (tab) {
            case 'informacion-general':
                return formInformacionGeneral
            case 'descripciones-banner':
                return formDescripciones
            case 'images':
                return formImages
            case 'premios':
                return null
        }
    }

    /**
    * @method getSorteo
    * @description Obtiene la informacion de un sorteo
    */
    const getSorteo = () => {
        setLoading(true)
        axios.get('/sorteo/' + sorteo_id)
            .then(({ data }) => {
                setSorteo(data)
            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al obtener la información")
            })
            .finally(() => setLoading(false))
    }


    const goTo = () => {
        if (tab == "informacion-general") setTab('descripciones-banner')
        else if (tab == "descripciones-banner") setTab('images')
        else if (tab == "images") setTab('premios')
        else onClose()

    }

    const onFinish = (values, goTo) => {



        try {
            if (values?.fechas?.length > 0) {
                values.fecha_inicio = values.fechas[0]
                values.fecha_finalizacion = values.fechas[1]
            }

            if (values?.estado_id?._id) values.estado_id = values?.estado_id?._id
            if (values?.pais_id?._id) values.pais_id = values?.pais_id?._id


            const formData = new FormData()
            formData.appendMultiple({
                sorteo_id: sorteoId,
                ...values,
                banner: undefined, //JSON.stringify(values.banner),
                imagenes_celular: undefined,//JSON.stringify((values.imagenes_celular ?? []).filter(e => !e.originFileObj)),
                imagenes_escritorio: undefined,//JSON.stringify((values.imagenes_escritorio ?? []).filter(e => !e.originFileObj)),
            })


            console.log("values.banner", values.banner)
            if (values.banner) {
                if (values.banner.file instanceof File) values.banner = values.banner.file

                if (values.banner instanceof File)
                    formData.append("banner", values.banner, values.banner.name)
                else
                    formData.append("banner", JSON.stringify(null))
            }


            if (Array.isArray(values.imagenes_escritorio)) {
                let imagenes = []
                values.imagenes_escritorio = values.imagenes_escritorio.map(imagen => {

                    if (imagen.originFileObj instanceof File) imagen = imagen.originFileObj
                    if (imagen.file instanceof File) imagen = imagen.file

                    if (imagen instanceof File)
                        formData.append("imagenes_escritorio", imagen, imagen.name)
                    else
                        imagenes.push(imagen)

                })
                formData.append("imagenes_escritorio", JSON.stringify(imagenes))
            }
            if (Array.isArray(values.imagenes_celular)) {
                let imagenes = []
                values.imagenes_celular = values.imagenes_celular.map(imagen => {

                    if (imagen.originFileObj instanceof File) imagen = imagen.originFileObj
                    if (imagen.file instanceof File) imagen = imagen.file

                    if (imagen instanceof File)
                        formData.append("imagenes_celular", imagen, imagen.name)
                    else
                        imagenes.push(imagen)

                })
                formData.append("imagenes_celular", JSON.stringify(imagenes))
            }


            if (sorteoId)
                update(formData)
            else {
                add(formData)
            }
        } catch (e) {
            console.log("Error al realizar submit", e)
        }
    }

    /**
    * @method addSorteo
    * @description Añade un nuevo registro de rifas
    */
    const add = (values) => {
        setLoading(true)
        axios.post('/sorteo', values, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({ data }) => {
                message.success("Creado con exito")
                setSorteo(data)
                setSorteoId(data._id)
            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al registrar")
            }).finally(() => setLoading(false))
    }

    /**
    * @method updateSorteo
    * @description Añade un nuevo registro de rifas
    */
    const update = (values) => {
        setLoading(true)
        axios.put('/sorteo/' + sorteoId, values, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({ data }) => {
                message.success("Actualizado con exito")
                setSorteo(data)
                setSorteoId(data._id)
                // if (sor)
                goTo()
            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al registrar")
            })
            .finally(() => setLoading(false))
    }


    useEffect(() => {
        if (sorteo_id) {
            getSorteo()
        }
    }, [])

    useImperativeHandle(ref, () => ({
        submit: () => {
            const cForm = getForm()

            console.log("cForm", cForm)
            if (cForm && cForm != null)
                cForm.submit()
            else
                onClose();
        },
    }));

    return <Spin spinning={loading}>
        <Tabs
            // defaultActiveKey=""
            activeKey={tab}
            onChange={e => {
                setTab(e)
            }}

            destroyInactiveTabPane={true}
            forceRender={true}
        >
            <TabPane
                tab="Información General"
                key="informacion-general"
            >
                <InformacionGeneral
                    form={formInformacionGeneral}
                    onFinish={onFinish}
                    sorteo={sorteo}
                />
            </TabPane>
            <TabPane
                disabled={!sorteoId}
                tab="Descripciones y Banner"
                key="descripciones-banner"
            >
                <Descripciones
                    form={formDescripciones}
                    onFinish={onFinish}
                    sorteo={sorteo}
                />
            </TabPane>
            <TabPane
                disabled={!sorteoId}
                tab="Imágenes"
                key="images"
            >
                <ImagesForm
                    form={formImages}
                    onFinish={onFinish}
                    sorteo={sorteo}
                />
            </TabPane>
            <TabPane
                disabled={!sorteoId}
                tab="Premios"
                key="premios"
            >
                <Premios
                    // form={formImages}
                    // onFinish={onFinish}
                    sorteo={sorteo}
                />
            </TabPane>
        </Tabs>
    </Spin>
});




export default function DrawerSorteoWrapper(props) {

    const { open = false, onClose = () => { }, sorteo_id } = props

    const sorteoDrawerRef = useRef()

    const saveValues = () => {
        sorteoDrawerRef.current.submit()
    }

    return <Drawer
        title={`${props.sorteo_id ? "Editar" : "Nuevo"} Sorteo`}
        placement={"bottom"}
        onClose={onClose}
        open={open}
        key={"bottom"}
        height={800}
        destroyOnClose={true}
        extra={
            <Space>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={saveValues} type="primary">
                    Aceptar
                </Button>
            </Space>
        }
    >
        <DrawerSorteoForwadRefWrapper ref={sorteoDrawerRef} {...props} />
    </Drawer>

}

// //componentes
// import PasosDrag from "./PasosDrag";
// import PremiosDrag from "./PremiosDrag";
// import Quill from "../../Widgets/Inputs/Quill";
// import { ImageDragger, Uploader } from "../../Widgets/Uploaders";
// import { SelectPais, SelectEstado } from "../../Widgets/Inputs/Selects";
// import Paragraph from "antd/lib/typography/Paragraph";

// const { Option } = Select;
// const { Dragger } = Upload;
// const { Title, Text } = Typography;
// const { RangePicker } = DatePicker;
// const moment = require('moment');


// function ImagePreviewer({ src }) {
//     const [visible, setVisible] = useState(false);
//     const [scaleStep, setScaleStep] = useState(0.5);

//     useEffect(() => {

//         if (src)
//             setVisible(true)
//         else
//             setVisible(false)

//     }, [src])

//     return <Image
//         style={{ display: 'none' }}
//         src={src}
//         preview={{
//             visible,
//             src, //: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
//             onVisibleChange: (value) => {
//                 setVisible(value);
//             },
//         }}
//     />
// };

// /**
//  * @class DraweRifa
//  * @description Drawer para el CRUD de Rifas
//  */
// class DraweRifa extends Component {

//     constructor(props) {
//         super(props)
//         this.state = {
//             loading: false,

//             roles: {
//                 data: [],
//                 limit: 10,
//                 page: 1,

//                 total: 0,
//                 pages: 0,
//             },
//             premios: [],
//             pasos: [],
//         }
//     }

//     componentDidMount() {
//         axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

//         if (this.props.sorteo_id) {
//             this.getSorteo()
//         } else {
//             this.getPais()
//         }


//     }

//     formRef = React.createRef();
//     premiosRef = React.createRef();
//     pasosRef = React.createRef();

//     submit = () => {
//         this.formRef.current.submit()
//     }


//     /**
//    * @method onFinish
//    * @description Se ejecuta al hacer submit en el formulario, formatea la informacion para que pueda ser leida en el server
//    */
//     onFinish = (values) => {

//         try {


//             console.log(' * * * * * *');
//             console.log(' * * * * * *');
//             console.log(' * * * * * *');
//             console.log(' * * * * * *');
//             console.log(' * * * * * *');
//             console.log(' * * * * * *');
//             console.log('values', values);
//             // return 

//             if (values?.fechas?.length > 0) {
//                 values.fecha_inicio = values.fechas[0]
//                 values.fecha_finalizacion = values.fechas[1]
//             }

//             const formData = new FormData()
//             formData.appendMultiple({
//                 ...values,
//                 banner: undefined,
//                 imagenes: undefined,
//                 descripciones: JSON.stringify(values.descripciones),
//                 imagenes_celular: undefined,
//                 imagenes_escritorio: undefined
//             })

//             if (values.imagenes) {
//                 for (const imagen of values?.imagenes) {
//                     console.log("imagen", imagen);
//                     if (imagen.originFileObj)
//                         formData.append("imagenes", imagen.originFileObj, imagen.name)
//                     else
//                         formData.append("imagenes", imagen)
//                 }
//             }

//             if (values.imagenes_escritorio) {
//                 for (const imagen of values?.imagenes_escritorio) {
//                     console.log("imagen", imagen);
//                     if (imagen.originFileObj)
//                         formData.append("imagenes_escritorio", imagen.originFileObj, imagen.name)
//                     else
//                         formData.append("imagenes_escritorio", imagen)
//                 }
//             }

//             if (values.imagenes_celular) {
//                 for (const imagen of values?.imagenes_celular) {
//                     console.log("imagenes_celular", imagen);
//                     if (imagen.originFileObj)
//                         formData.append("imagenes_celular", imagen.originFileObj, imagen.name)
//                     else
//                         formData.append("imagenes_celular", imagen)
//                 }
//             }

//             if (values.banner && values.banner instanceof File) {
//                 formData.append("banner", values.banner, values.banner.name)
//             }

//             //Obtenemos el array de premios del componente, y formateamos la infomacion
//             let premios = this.premiosRef.current.state.items
//             for (let premio of premios) {
//                 formData.append("premios_array", JSON.stringify({ ...premio, imagenes: premio.imagenes?.map(e => ({ name: e.name, id: premio.id })) }))

//                 if (premio.imagenes?.length > 0)
//                     for (let archivo of premio.imagenes) {
//                         if (!(archivo instanceof File)) continue;

//                         let nameWithoutExtension = archivo.name.split('.').slice(0, -1).join('.');
//                         let new_archivo = new File([archivo], `${nameWithoutExtension}_${premio.id}.${archivo.type.split('/').pop()}`, { type: archivo.type });

//                         formData.append("imagenes_premios", new_archivo, new_archivo.name)
//                     }
//             }

//             //Obtenemos el array de premios del componente, y formateamos la infomacion
//             let pasos = this.pasosRef.current.state.items
//             for (let paso of pasos) {
//                 formData.append("pasos_array", JSON.stringify({ ...paso, imagen: { name: paso.imagen.name, id: paso.id } }))
//                 if (paso.imagen instanceof File) {
//                     let nameWithoutExtension = paso.imagen.name.split('.').slice(0, -1).join('.');
//                     let new_archivo = new File([paso.imagen], `${nameWithoutExtension}_${paso.id}.${paso.imagen.type.split('/').pop()}`, { type: paso.imagen.type });
//                     formData.append("imagenes_pasos", new_archivo, new_archivo.name)
//                 }
//             }

//             if (this.props.sorteo_id)
//                 this.updateSorteo(formData)
//             else {
//                 this.addSorteo(formData)
//             }

//         } catch (e) {
//             console.log("Error al realizar submit", e)
//         }
//     }

//     /**
//     * @method addSorteo
//     * @description Añade un nuevo registro de rifas
//     */
//     addSorteo = (values) => {
//         this.setState({ loading: true })
//         axios.post('/sorteo', values)
//             .then(response => {
//                 message.success("Creado con exito")
//                 this.props.onClose(true)
//             })
//             .catch(error => {
//                 console.log("error", error);
//                 message.error(error?.response?.data?.message ?? "Error al registrar")
//             }).finally(() => this.setState({ loading: false }))
//     }

//     /**
//     * @method updateSorteo
//     * @description Añade un nuevo registro de rifas
//     */
//     updateSorteo = (values) => {
//         this.setState({ loading: true })


//         axios.put('/sorteo/' + this.props.sorteo_id, values)
//             .then(response => {
//                 message.success("Actualizado con exito")
//                 this.props.onClose(true)
//             })
//             .catch(error => {
//                 console.log("error", error);
//                 message.error(error?.response?.data?.message ?? "Error al actualizar")
//             }).finally(() => this.setState({ loading: false }))
//     }

//     /**
//     * @method getSorteo
//     * @description Obtiene la informacion de un sorteo
//     */
//     getSorteo = () => {
//         this.setState({ loading: true })
//         axios.get('/sorteo/' + this.props.sorteo_id)
//             .then(({ data }) => {
//                 console.log("data", data);
//                 this.formRef.current.setFieldsValue({
//                     ...data,
//                     fechas: [moment(data.fecha_inicio), moment(data.fecha_finalizacion)],
//                     banner: data.banner ? {
//                         uid: data.banner.file,
//                         name: data.banner.name,
//                         status: 'done',
//                         fromDB: true,
//                         url: `${axios.defaults.baseURL}/sorteo/${this.props.sorteo_id}?banner=${data.banner.file}&Authorization=${sessionStorage.getItem("token")}`
//                     } : null,
//                     pais_id: data.pais_id ? {
//                         label: data.pais_id.nombre,
//                         value: data.pais_id._id
//                     } : null,
//                     estado_id: data.estado_id ? {
//                         label: data.estado_id.nombre,
//                         value: data.estado_id._id
//                     } : null,

//                     imagenes: Array.isArray(data.imagenes) ? data.imagenes.map((file, index) => ({
//                         uid: file.file,
//                         name: file.name,
//                         status: 'done',
//                         fromDB: true,
//                         url: axios.defaults.baseURL + "/sorteo/" + this.props.sorteo_id + "?imagen=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
//                     })) : [],

//                     imagenes_celular: Array.isArray(data.imagenes_celular) ? data.imagenes_celular.map((file, index) => ({
//                         uid: file.file,
//                         name: file.name,
//                         status: 'done',
//                         fromDB: true,
//                         url: axios.defaults.baseURL + "/sorteo/" + this.props.sorteo_id + "?imagen=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
//                     })) : [],

//                     imagenes_escritorio: Array.isArray(data.imagenes_escritorio) ? data.imagenes_escritorio.map((file, index) => ({
//                         uid: file.file,
//                         name: file.name,
//                         status: 'done',
//                         fromDB: true,
//                         url: axios.defaults.baseURL + "/sorteo/" + this.props.sorteo_id + "?imagen=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
//                     })) : [],

//                 })

//                 this.setState({
//                     premios: data.premios,
//                     pasos: data.pasos,
//                     tipo: data.tipo,
//                 })
//             })
//             .catch(error => {
//                 console.log("error", error);
//                 message.error(error?.response?.data?.message ?? "Error al obtener la información")
//             }).finally(() => this.setState({ loading: false }))
//     }

//     /**
//     * @method getPais
//     * @description Obtiene la informacion de un pais
//     */
//     getPais = () => {
//         axios.get('/paises', {
//             params: {
//                 search: "mexico",
//             },
//         }).then(({ data }) => {
//             if (data.data[0]) {
//                 this.formRef.current.setFieldsValue({ pais_id: { label: data.data[0].nombre, value: data.data[0]._id } })
//                 this.setState({ pais_id: data.data[0]._id })

//             }
//         })
//     }

//     handleWarning = async (values) => {

//         // console.log(await this.formRef.current.validateFields())
//         this.formRef.current.validateFields()
//         this.onFinish(values)

//     }

//     render() {

//         return (
//             <Spin spinning={this.state.loading}>
//                 <Form
//                     layout={"vertical"}
//                     ref={this.formRef}
//                     onFinish={this.handleWarning}
//                     name="my-form"
//                     className="mb-3"
//                 >
//                     <Row gutter={[16, 4]} style={{ justifyContent: 'center', margin: '0 auto', maxWidth: 1160 }}>
//                         <Col xs={24} md={12}>
//                             <Form.Item
//                                 label="URL"
//                                 name="url"
//                             >
//                                 <Input
//                                     disabled={true}
//                                 />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={6}>
//                             <Form.Item
//                                 label="Tipo Sorteo"
//                                 name="tipo"
//                                 rules={[{
//                                     required: true,
//                                     message: "Por favor seleccione un tipo"
//                                 }]}
//                             >
//                                 <Select
//                                     onSelect={(tipo) => this.setState({ tipo })}
//                                 >
//                                     <Option value={1}>Venta</Option>
//                                     <Option value={2}>Promocion</Option>
//                                 </Select>
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={6}>
//                             <Form.Item
//                                 label="Estatus del Sorteo"
//                                 name="estatus"
//                                 rules={[{
//                                     required: true,
//                                     message: "Por favor seleccione un tipo"
//                                 }]}
//                             // 0 - Cancelada
//                             // 1 - Cerrada
//                             // 2 - En Venta
//                             // 3 - Vendida
//                             // 4 - Concluida
//                             >
//                                 <Select>
//                                     <Option value={0}>
//                                         <Badge color="#f50" text={<>
//                                             <CloseCircleOutlined style={{ color: '#f50', marginRight: 8 }} />Cancelada
//                                         </>} />
//                                     </Option>
//                                     <Option value={1}>
//                                         <Badge color="#888888" text={<>
//                                             <LockOutlined style={{ color: '#888888', marginRight: 8 }} />Cerrada
//                                         </>} />
//                                     </Option>
//                                     <Option value={2}>
//                                         <Badge color="#52c41a" text={<>
//                                             <ShoppingOutlined style={{ color: '#52c41a', marginRight: 8 }} />En Venta
//                                         </>} />
//                                     </Option>
//                                     <Option value={3}>
//                                         <Badge color="#1890ff" text={<>
//                                             <CheckCircleOutlined style={{ color: '#1890ff', marginRight: 8 }} />Vendida
//                                         </>} />
//                                     </Option>
//                                     <Option value={4}>
//                                         <Badge color="#d32f2f" text={<>
//                                             <CheckOutlined style={{ color: '#00e600', marginRight: 8 }} />Concluida
//                                         </>} />
//                                     </Option>
//                                 </Select>
//                             </Form.Item>
//                         </Col>
//                         {this.state.tipo === 2 ? <Col xs={24} md={4}>
//                             <Form.Item
//                                 label="ID de Facebook"
//                                 name="facebook_id"
//                             >
//                                 <Input />
//                             </Form.Item>
//                         </Col> : null}

//                         <Col xs={24} md={this.state.tipo === 2 ? 12 : 16}>
//                             <Form.Item
//                                 label="Nombre del Concurso o Rifa"
//                                 name="nombre"
//                                 rules={[{
//                                     required: true,
//                                     message: "Por favor indique el nombre del concurso"
//                                 }]}
//                             >
//                                 <Input
//                                     onChange={(event) => {
//                                         this.formRef.current.setFieldsValue({
//                                             url: event.target.value.replace(/\s+/g, '-').toLowerCase().replace(/[^a-z0-9ñ\-]+/g, '', '')
//                                         })
//                                     }}
//                                 />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={8}>
//                             <Form.Item
//                                 label="Inicio y Finalización del Evento"
//                                 name="fechas"
//                                 rules={[{
//                                     required: true,
//                                     message: "Por favor defina las fechas del evento"
//                                 }]}
//                             >
//                                 <RangePicker
//                                     className="width-100"
//                                 />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={16}>
//                             <Form.Item
//                                 label="Costo del Boleto"
//                                 name="costo_boleto"
//                                 rules={[{
//                                     required: true,
//                                     message: "Por favor indique el costo del boleto"
//                                 }]}
//                             >
//                                 <InputNumber defaultValue={0} className="width-100"
//                                     formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
//                                     parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
//                                 />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={8}>
//                             <Form.Item
//                                 label="Cantidad de Boletos"
//                                 name="cantidad_boletos"
//                                 rules={[{
//                                     required: true,
//                                     message: "Por favor indique la cantidad de boletos para el sorteo"
//                                 }]}
//                             >
//                                 <InputNumber
//                                     className="width-100"
//                                     formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
//                                     parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
//                                 />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={12}>
//                             <Form.Item
//                                 label="Dirección / Localización del Evento"
//                                 name="direccion"
//                             >
//                                 <Input />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={5}>
//                             <Form.Item
//                                 label="Ciudad"
//                                 name="ciudad"
//                             >
//                                 <Input />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={4}>
//                             <Form.Item
//                                 label="Pais"
//                                 name="pais_id"
//                                 rules={[{
//                                     required: true,
//                                     message: "Seleccione un pais"
//                                 }]}
//                             >
//                                 <SelectPais onSelect={(pais_id) => {
//                                     this.setState({ pais_id })
//                                     this.formRef.current.setFieldsValue({ estado_id: undefined })
//                                 }} />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={3}>
//                             <Form.Item
//                                 label="Estado"
//                                 name="estado_id"
//                                 rules={[{
//                                     required: true,
//                                     message: "Seleccione un estado"
//                                 }]}
//                             >
//                                 <SelectEstado pais_id={this.state.pais_id} />
//                             </Form.Item>
//                         </Col>
//                         <div className="mb-2">
//                             <Divider style={{ marginBottom: "10px" }} >Imagenes</Divider>
//                             <Text italic className="mb-2"><small>Si no hay imagenes en las opciones de Escritorio y Celular se mostraran solo las imagenes que se encuentran en "Archivos e imagenes"</small></Text>

//                         </div>
//                         <Col xs={24} md={20}>
//                             <Form.Item
//                                 label="Archivos e imagenes"
//                                 name="imagenes"
//                                 valuePropName="fileList"
//                                 getValueFromEvent={e => {
//                                     if (Array.isArray(e)) {
//                                         return e;
//                                     }
//                                     return e?.fileList;
//                                 }}>
//                                 <Uploader
//                                     {...this.props.sorteo_id ? {
//                                         method: "PUT",
//                                         name: "imagen",
//                                         headers: {
//                                             authorization: sessionStorage.getItem("token")
//                                         },
//                                         action: axios.defaults.baseURL + '/transacciones',
//                                         onRemove: e => {

//                                             console.log('e', e);
//                                             axios.put('/sorteo/' + this.props.sorteo_id, {
//                                                 id: this.props.sorteo_id,
//                                                 imagen: e.uid,
//                                             })
//                                         }
//                                     } : {}}
//                                     listType="picture-card"
//                                     beforeUpload={e => {
//                                         console.log('e', e)
//                                         return false
//                                     }}
//                                     previewFile={async (file, ...args) => {

//                                         console.log('e', file, args);
//                                         // return file;
//                                         return new Promise((resolve, reject) => {

//                                             var reader = new FileReader()

//                                             reader.readAsDataURL(file)

//                                             reader.onload = function () {
//                                                 console.log(reader)
//                                                 resolve(encodeURI(reader.result))
//                                             }
//                                             reader.onerror = function (error) {
//                                                 reject(error)
//                                                 console.log('Error: ', error)
//                                             };
//                                         })
//                                     }}
//                                     onPreview={(file) => this.setState({ imagePreview: encodeURI(file.thumbUrl) })}
//                                 >
//                                     <div>
//                                         <UploadOutlined />
//                                         <br />
//                                         Subir Imagenes
//                                     </div>
//                                     <ImagePreviewer
//                                         src={this.state.imagePreview}

//                                     />
//                                 </Uploader>
//                             </Form.Item>

//                         </Col>
//                         <Col xs={24} md={20}>
//                             <Form.Item
//                                 label="Imagenes que se visualizarán "
//                                 name="imagenes_escritorio"
//                                 valuePropName="fileList"
//                                 getValueFromEvent={e => {
//                                     if (Array.isArray(e)) {
//                                         return e;
//                                     }
//                                     return e?.fileList;
//                                 }}
//                                 rules={[
//                                     // {
//                                     //     validator: (_, value) => {
//                                     //         console.log("value", value);
//                                     //         console.log("other value", this.formRef.current.getFieldValue("imagenes_celular").length);

//                                     //         // if (value.length !== this.formRef.current.getFieldValue("imagenes_celular").length) {
//                                     //         //     return Promise.reject("Las secciones deben de tener la misma cantidad de imagenes");
//                                     //         // }

//                                     //         return Promise.resolve();
//                                     //     }
//                                     // },
//                                 ]}
//                             >
//                                 <Uploader
//                                     {...this.props.sorteo_id ? {
//                                         method: "PUT",
//                                         name: "imagenes_escritorio",
//                                         headers: {
//                                             authorization: sessionStorage.getItem("token")
//                                         },
//                                         action: axios.defaults.baseURL + '/transacciones',
//                                         onRemove: e => {

//                                             console.log('e', e);
//                                             axios.put('/sorteo/' + this.props.sorteo_id, {
//                                                 id: this.props.sorteo_id,
//                                                 imagenes_escritorio: e.uid,
//                                             })
//                                         }
//                                     } : {}}
//                                     listType="picture-card"
//                                     beforeUpload={e => {
//                                         console.log('e', e)
//                                         return false
//                                     }}
//                                     previewFile={async (file, ...args) => {

//                                         console.log('e', file, args);
//                                         // return file;
//                                         return new Promise((resolve, reject) => {

//                                             var reader = new FileReader()

//                                             reader.readAsDataURL(file)

//                                             reader.onload = function () {
//                                                 console.log(reader)
//                                                 resolve(encodeURI(reader.result))
//                                             }
//                                             reader.onerror = function (error) {
//                                                 reject(error)
//                                                 console.log('Error: ', error)
//                                             };
//                                         })
//                                     }}
//                                     onPreview={(file) => this.setState({ imagePreview: encodeURI(file.thumbUrl) })}
//                                 >
//                                     <div>
//                                         <UploadOutlined />
//                                         <br />
//                                         Subir Imagenes
//                                     </div>
//                                     <ImagePreviewer
//                                         src={this.state.imagePreview}

//                                     />
//                                 </Uploader>
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={20}>
//                             <Form.Item
//                                 label="Imagenes Celular"
//                                 name="imagenes_celular"
//                                 valuePropName="fileList"
//                                 getValueFromEvent={e => {
//                                     if (Array.isArray(e)) {
//                                         return e;
//                                     }
//                                     return e?.fileList;
//                                 }}
//                                 rules={[{
//                                     validator: (_, value) => {
//                                         console.log("value", value);
//                                         console.log("other value", this.formRef.current.getFieldValue("imagenes_escritorio").length);

//                                         // if (value.length !== this.formRef.current.getFieldValue("imagenes_escritorio").length) {
//                                         //     return Promise.reject("Las secciones deben de tener la misma cantidad de imagenes");
//                                         // }

//                                         return Promise.resolve();
//                                     }
//                                 }]}
//                             >
//                                 <Uploader
//                                     {...this.props.sorteo_id ? {
//                                         method: "PUT",
//                                         name: "imagenes_celular",
//                                         headers: {
//                                             authorization: sessionStorage.getItem("token")
//                                         },
//                                         action: axios.defaults.baseURL + '/transacciones',
//                                         onRemove: e => {

//                                             console.log('e', e);
//                                             axios.put('/sorteo/' + this.props.sorteo_id, {
//                                                 id: this.props.sorteo_id,
//                                                 imagenes_celular: e.uid,
//                                             })
//                                         }
//                                     } : {}}
//                                     listType="picture-card"
//                                     beforeUpload={e => {
//                                         console.log('e', e)
//                                         return false
//                                     }}
//                                     previewFile={async (file, ...args) => {

//                                         console.log('e', file, args);
//                                         // return file;
//                                         return new Promise((resolve, reject) => {

//                                             var reader = new FileReader()

//                                             reader.readAsDataURL(file)

//                                             reader.onload = function () {
//                                                 console.log(reader)
//                                                 resolve(encodeURI(reader.result))
//                                             }
//                                             reader.onerror = function (error) {
//                                                 reject(error)
//                                                 console.log('Error: ', error)
//                                             };
//                                         })
//                                     }}
//                                     onPreview={(file) => this.setState({ imagePreview: encodeURI(file.thumbUrl) })}
//                                 >
//                                     <div>
//                                         <UploadOutlined />
//                                         <br />
//                                         Subir Imagenes
//                                     </div>
//                                     <ImagePreviewer
//                                         src={this.state.imagePreview}

//                                     />
//                                 </Uploader>
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={20}>
//                             <div className="mb-2">
//                                 <Divider style={{ marginBottom: "10px" }} >Descripciones</Divider>
//                                 <Text italic className="mb-2"><small>Debe de haber la misma cantidad de descripciones que imagenes, si se deja el campo vacio se utlilizará la descripción predeterminada para el evento, las descripciones se asignaran en el mismo orden de las imagenes que estan sobre esta sección</small></Text>

//                             </div>
//                             <Form.List
//                                 name="descripciones"
//                                 label="Descripciones"
//                             >

//                                 {
//                                     (fields, { add, remove }, { errors }) => (
//                                         <>
//                                             {
//                                                 fields.map((field, index) => (
//                                                     <Form.Item
//                                                         required={false}
//                                                         key={field.key}
//                                                     >
//                                                         <Form.Item
//                                                             {...field}
//                                                             name={field.name}
//                                                             noStyle
//                                                         >
//                                                             <Input placeholder="Descripción" style={{ width: '90%' }} />
//                                                         </Form.Item>
//                                                         {fields.length > 1 ? (
//                                                             <AiOutlineMinusCircle
//                                                                 className="ml-1"
//                                                                 onClick={() => remove(field.name)}
//                                                             />
//                                                         ) : null}
//                                                     </Form.Item>
//                                                 ))
//                                             }
//                                             <Form.Item>
//                                                 <Button
//                                                     type="dashed"
//                                                     onClick={() => add()}
//                                                     style={{ width: '100%' }}

//                                                 >
//                                                     Agregar Descripción
//                                                 </Button>


//                                             </Form.Item>
//                                             <Form.ErrorList errors={errors} />
//                                         </>

//                                     )
//                                 }

//                             </Form.List>
//                         </Col>

//                         <Col xs={24} md={20}>
//                             <Form.Item
//                                 label="Banner del Evento"
//                                 name="banner"
//                             >
//                                 <ImageDragger
//                                     onRemove={() => {
//                                         axios.put('/sorteo/' + this.props.sorteo_id, {
//                                             delete_banner: true
//                                         })
//                                     }}
//                                 />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={20}>
//                             <Form.Item
//                                 label="Descripción del evento"
//                                 name="descripcion"
//                             >
//                                 <Input.TextArea />
//                             </Form.Item>
//                         </Col>
//                         <Col xs={24} md={20}>
//                             <Form.Item
//                                 label="Contenido"
//                                 name="contenido"
//                             >
//                                 <Quill />
//                             </Form.Item>
//                         </Col>

//                     </Row>
//                     <Divider>Premios</Divider>
//                     <Row justify="center" gutter={[16, 4]}>
//                         <Col xs={24} md={20}>
//                             <PremiosDrag
//                                 ref={this.premiosRef}
//                                 premios={this.state.premios}
//                             />
//                         </Col>
//                     </Row>
//                     <Divider>Pasos para Participar</Divider>
//                     <Row justify="center" gutter={[16, 4]}>
//                         <Col xs={24} md={20}>
//                             <PasosDrag
//                                 ref={this.pasosRef}
//                                 pasos={this.state.pasos}
//                                 sorteo_id={this.props.sorteo_id}
//                             />
//                         </Col>
//                     </Row>
//                 </Form>
//             </Spin>
//         )
//     }


// }



// export default function Modals(props) {

//     const { open = false, onClose = () => { }, usuario } = props

//     const formulario = useRef()

//     const saveValues = () => {
//         formulario.current.submit()
//     }

//     return <Drawer
//         title={`${props.sorteo_id ? "Editar" : "Nuevo"} Sorteo`}
//         placement={"bottom"}
//         onClose={onClose}
//         open={open}
//         key={"bottom"}
//         height={800}
//         destroyOnClose={true}
//         extra={
//             <Space>
//                 <Button onClick={onClose}>Cancelar</Button>
//                 <Button onClick={saveValues} type="primary">
//                     Aceptar
//                 </Button>
//             </Space>
//         }
//     >
//         <DraweRifa
//             {...props}
//             ref={formulario}
//         />
//     </Drawer>

// }