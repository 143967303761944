import React, { Component } from 'react';

import { Layout, Row, Col, Menu, Typography, Button, Divider, Card, Input } from 'antd';

import { MailIcon, TelefonoIcon, UbicacionIcon } from '../Widgets/Iconos';
import { FaSquareFull } from 'react-icons/fa'
import { BsInstagram, BsFacebook, BsTiktok } from 'react-icons/bs'
import { AiOutlineTwitter } from 'react-icons/ai'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import WidgetFacebook from './WidgetFacebook';
import '../../Styles/Public/footer.scss'

const { Header, Footer, Sider, Content } = Layout;
const { Text } = Typography;

class FooterPublic extends Component {
    render() {
        return (
            <>
                <Footer id='contacto' className='gradient-footer footer'>
                    <Row gutter={[0, 16]} align={"middle"} justify={"space-between"}>
                        <Col xs={24} sm={24} md={24} lg={6} xl={8} xxl={4} className=' mr-1'>
                            <img className="w-100" src="/img/logo.svg" alt="José Hermosillo" style={{ maxWidth: '400px' }} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={17} xl={15} xxl={15} className="">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Row align={"middle"}>
                                        <Col span={24} className='flex-left'>
                                            <TelefonoIcon />
                                            <a className="flex-column-left pl-1" href='https://api.whatsapp.com/send?phone=5213471017645&text=%C2%A1Hola!%20Quiero%20contactar%20a%20FugapaDurango'>
                                                <Text className='info-titulo'>FUGA PA DURANGO</Text>
                                                <Text className='info-datos'> +52 1 347 101 7645 </Text>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Row align={"middle"}>
                                        <Col span={24} className='flex-left'>
                                            <MailIcon />
                                            <a className="flex-column-left pl-1" href='mailto:fugapadurango2023@gmail.com'>
                                                <Text className='info-titulo'> Correo Electrónico </Text>
                                                <Text className='info-datos'>fugapadurango2023@gmail.com</Text>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Row align={"middle"}>
                                        <Col span={24} className='flex-left'>
                                            <UbicacionIcon />
                                            <a className="flex-column-left pl-1" href='https://goo.gl/maps/F8tRWazQwmpWDY858?coh=178571&entry=tt' target='_blank'>
                                                <Text className='info-titulo'>Ubicación</Text>
                                                <Text className='info-datos'>6052 L st Omaha Nebraska 68117</Text>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider style={{ backgroundColor: "#6C6D72" }} />
                    <Row align={"middle"}>
                        <Col xs={24} sm={24} md={24} lg={14} xl={12} xxl={14}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                    <Row className="elementos-footer">
                                        <ul>
                                            <li>
                                                <HashLink smooth to={'/#inicio'}>
                                                    INICIO
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink smooth to={'/#nosotros'}>
                                                    NOSOTROS
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink smooth to={'/#ultimos-sorteos'}>
                                                    SORTEOS
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink smooth to={'/#ganadores'}>
                                                    GANADORES
                                                </HashLink>
                                            </li>
                                            <li>
                                                <HashLink smooth to={'/#contacto'}>
                                                    CONTACTO
                                                </HashLink>
                                            </li>
                                        </ul>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={12} xxl={10}>
                            <WidgetFacebook />
                        </Col>
                    </Row>
                    <Divider style={{ backgroundColor: "#6C6D72" }} />
                    <Row align={"middle"} justify={"space-between"}>
                        <Col >
                            <Text className='copyright'>Copyright © ThrowCode Developement and Design</Text>
                        </Col>
                        <Col>
                            <Text className='redes'>¡Síguenos en nuestras redes sociales!
                                <a href='https://www.instagram.com/sorteosfugapadurango/' target='_blank'><BsInstagram className='ml-1' /></a>
                                <a href='https://www.facebook.com/sorteosfugapadurango/' target='_blank'><BsFacebook className='ml-1' /></a>
                                <a href='https://www.tiktok.com/@fugapadgo/' target='_blank'><BsTiktok className='ml-1' /></a>
                            </Text>
                        </Col>
                    </Row>

                </Footer>
            </>
        );
    }
}

export default function (props) {

    return <FooterPublic />

}