import React from 'react'
import { Route, Routes } from "react-router-dom";


import Clientes from '../Components/Admin/Clientes/Clientes';
import ClientesDetalle from '../Components/Admin/Clientes/ClientesDetalle';


/**
 * 
 * @export
 * @function RouterClientes
 * @description Router for Clientes routes 
 */
function RouterClientes(props) {
  return (
    <Routes>
    	<Route path="" element={<Clientes {...props}/>}/>
    	<Route path="/detalle/:cliente_id" element={<ClientesDetalle/>}/>
    </Routes>
  )
}

export default RouterClientes