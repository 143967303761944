import React from 'react'
import { Route, Routes } from "react-router-dom";

import Ventas from '../Components/Admin/Ventas/Ventas';

/**
 * 
 * @export
 * @function RouterUsuarios
 * @description Router for Usuarios routes 
 */
function RouterVentas(props) {
  return (
    <Routes>
      <Route path="" element={<Ventas {...props} />} />
    </Routes>
  )
}

export default RouterVentas