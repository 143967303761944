import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Modal, Spin, PageHeader, Layout, Space, message, Card, List, Select, Tag } from 'antd'
import axios from 'axios';
import moment from 'moment'
import { ExclamationCircleOutlined } from '@ant-design/icons';

//componentes
import Logged from "../../../../Hooks/Logged";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconMail, IconEye } from '../../../Widgets/Iconos';
import { AiOutlineEye } from 'react-icons/ai';

//modal
import ModalVentas from '../../Ventas/ModalVentas'
import ModalVentasDetalle from '../../Ventas/ModalDetalle'


const { Content } = Layout
const { Text } = Typography
const { Option } = Select

/**
 *
 *
 * @export
 * @class Ventas
 * @extends {Component}
 * @description Vista donde se listan todos las ventas
 */
export default class Ventas extends Component {
    static contextType = Logged;

    back = () => {
        this.props.history.goBack();
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            ventas: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            },
            venta_id: null
        }
    }

    componentDidMount() {
        this.getVentas()
    }


    /**
    *
    * @memberof Ventas
    * @method getVentas
    * @description Obtiene la informacion de las ventas
    */
    getVentas = async ({
        page = this.state.ventas.page,
        limit = this.state.ventas.limit,
        search = this.props.search
    } = this.state.ventas) => {

        this.setState({ loading: true })

        axios.get('/ventas', {
            params: {
                limit,
                page,
                cliente_id: this.props.cliente_id
            }
        })
            .then(({ data }) => {
                this.setState({ ventas: data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof Ventas
     * @method updateVentaStatus
     * @description Actualiza el estado de una venta y maneja la confirmación para cancelar ventas.
     */
    updateVentaStatus = (_id, estatus) => {

        if(estatus === 2){
            Modal.confirm({
                title: 'Cancelar venta',
                icon: <ExclamationCircleOutlined />,
                content: '¿Está seguro de cancelar la venta? Una vez cancelada, los boletos volverán a estar disponibles y esta venta no podrá ser modificada.',
                okText: 'Si',
                cancelText: 'Cancelar',
                onOk: () => this.updateStatus(_id, estatus)
            });
        }else {
            this.updateStatus(_id, estatus)
        }
    }

    /**
     * @memberof Ventas
     * @method updateStatus
     * @description Actualiza el estado de una venta
     */
    updateStatus = (_id, estatus) => {
        this.setState({ loading: true })
        axios.put('/venta/' + _id, {
            estatus,
        })
        .then(response => {
            this.props?.getCliente()
            this.getVentas()
        })
        .catch(error => {
            if (error.response?.data)
                message.error(error.response.data)
            else
                message.error('Error al actualizar la venta')
        })
        .finally(() => this.setState({ loading: false }))
    }

    renderEstatus = (item) => {
        if (typeof item.estatus != 'number' || item.estatus < 0 || item.estatus > 4)
            item.estatus = 1

        return <Select
            bordered={false}
            value={item.estatus}
            style={{ minWidth: 150 }}
            onChange={(estatus) => {

                // console.log('e',e );
                this.updateVentaStatus(item._id, estatus)
            }}
        >   
            <Option value={0}>
                <Tag color="blue" style={{ width: "100%" }}>No se completo la transacción</Tag>
            </Option>
            <Option value={1}>
                <Tag color="yellow" style={{ width: "100%" }}>Pendiente</Tag>
            </Option>
            <Option value={4}>
                <Tag color="orange" style={{ width: "100%" }}>Por Confirmar</Tag>
            </Option>
            <Option value={2}>
                <Tag color="red" style={{ width: "100%" }}>Cancelado</Tag>
            </Option>
            <Option value={3}>
                <Tag color="green" style={{ width: "100%" }}>Completado</Tag>
            </Option>
        </Select>
    }



    render() {

        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Ventas" }}
                    dataSource={this.state.ventas.data}
                    pagination={{
                        current: this.state.ventas.page,
                        pageSize: this.state.ventas.limit,
                        total: this.state.ventas.total,
                        hideOnSinglePage: false,
                        position: 'bottom',
                        onChange: (page, limit) => this.getVentas({ page, limit })

                    }}
                    header={<Row className="component-list-header width-100 pr-1 pl-1" >
                        <Col span={7} className="center">
                            <Text strong>Cliente</Text>
                        </Col>

                        <Col span={5} className="center">
                            <Text strong>Total</Text>
                        </Col>
                        <Col span={5} className="center">
                            <Text strong>Fecha</Text>
                        </Col>
                        <Col span={5} className="center">
                            <Text strong>Estatus</Text>
                        </Col>
                        <Col span={2} className="center">
                            <Text strong>Acciones</Text>
                        </Col>

                    </Row>
                    }

                    renderItem={item => (
                        <List.Item className="component-list-item">

                            <Card className="card-list">
                                <Row className="width-100 " gutter={[8, 8]}>

                                    <Col xs={12} lg={7} className="center">
                                        <Text strong ellipsis>
                                                {item?.cliente_id?.nombre} {item?.cliente_id?.apellido}
                                                {/* {item?.cliente_id?.apellido_materno} */}
                                        </Text>
                                    </Col>

                                    <Col xs={12} lg={5} className="center">
                                        <Text className="text-gray">{item?.monto?.toMoney(true)} USD</Text>
                                    </Col>
                                    <Col xs={12} lg={5} className="center">
                                        <Text className="text-gray">{moment(item.fecha).format('LLLL')}</Text>
                                    </Col>
                                    <Col xs={12} lg={5} className="center">
                                        <Text className="text-gray">{this.renderEstatus(item)}</Text>
                                    </Col>
                                    <Col xs={24} lg={2} className="center">
                                        <Space>
                                            <Button type="primary" icon={<IconEdit />} onClick={() => this.setState({ modalVentas: true, venta_id: item._id })} />
                                            <Button type="primary" icon={<AiOutlineEye />} onClick={() => this.setState({ modalVentasDetalle: true, venta_id: item._id })} />
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVentas: true })} />
                <ModalVentas
                    visible={this.state.modalVentas}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modalVentas: false, venta_id: undefined })
                        if (flag === true) {
                            this.getVentas()
                            this.props.getCliente()
                        }
                    }}
                    initialValues={this.props.initialValues}
                />
                <ModalVentasDetalle
                    visible={this.state.modalVentasDetalle}
                    venta_id={this.state.venta_id}
                    onClose={(flag) => {
                        this.setState({ modalVentasDetalle: false, venta_id: undefined })
                        if (flag === true) {
                            this.getVentas()
                            this.props.getCliente()
                        }
                    }}
                />
            </>
        )
    }
}
