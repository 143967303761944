import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Modal, Spin, PageHeader, Layout, Space, message, Card, List } from 'antd'
import axios from 'axios';
import moment from 'moment'

//componentes
import Logged from "../../../../Hooks/Logged";
import { AiOutlineEye } from 'react-icons/ai';
import { ButtonView } from "../../../Widgets/Buttons";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import { IconDelete, IconEdit, IconMail, IconEye } from '../../../Widgets/Iconos';
//modal
import ModalVentas from '../../Ventas/ModalVentas'
import ModalVentasDetalle from '../../Ventas/ModalDetalle'


const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class VentasTab
 * @extends {Component}
 * @description Vista donde se listan todos las ventas
 */
export default class VentasTab extends Component {
    static contextType = Logged;


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            loading: false,
            clientes: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            venta_id: null
        }
    }

    componentDidMount() {
        this.getClientes()
    }


    /**
    *
    * @memberof VentasTab
    * @method getClientes
    * @description Obtiene los clientes que han comprado boletos del sorteo
    */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.props.search
    } = this.state.clientes) => {
        this.setState({ loading: true })
        axios.get('/clientes', {
            params: {
                page,
                limit, 
                search,
                sorteo_id: this.props.sorteo_id
            }
        }).then(({ data }) => {
        	console.log("data", data);

            this.setState({
                clientes: data
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }

    renderEstatus(estatus) {
        switch (estatus) {
            case '1':
                return <Text type="warning">Pendiente</Text>
            case '2':
                return <Text type="danger">Cancelado</Text>
            case '3':
                return <Text type="success">Completado</Text>
            default:
                return <Text type="warning">Pendiente</Text>
        }
    }



    render() {

        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Ventas" }}
                    dataSource={this.state.clientes.data}
                    pagination={{
                        current: this.state.clientes.page,
                        pageSize: this.state.clientes.limit,
                        total: this.state.clientes.total,
                        hideOnSinglePage: false,
                        position: 'bottom',
                        onChange: (page, limit) => this.getClientes({ page, limit })

                    }}
                    header={<Row className="component-list-header width-100 pr-1 pl-1"  gutter={[16, 16]}>
                        <Col xs={24} lg={8} className="center">
                            <Text strong>Nombre</Text>
                        </Col>
                        <Col xs={24} lg={4} className="center">
                            <Text strong>Telefono</Text>
                        </Col>
                        <Col xs={24} lg={4} className="center">
                            <Text strong>Email</Text>
                        </Col>
                        <Col xs={24} lg={4} className="center">
                            <Text strong>Vendedor</Text>
                        </Col>
                    </Row>}

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100 " gutter={[16, 16]}>

                                    <Col xs={12} lg={8} className="center">
                                        <Text>{item.nombre} {item.apellido_paterno} {item.apellido_materno ?? ""}</Text>
                                    </Col>
                                    <Col xs={12} lg={4} className="center">
                                        <Text>{item.telefono ?? "-"}</Text>
                                    </Col>
                                    <Col xs={12} lg={4} className="center">
                                        <Text>{item.email ?? "-"}</Text>
                                    </Col>
                                    <Col xs={12} lg={4} className="center">
                                        <Text>{item.vendedor_id?.nombre ?? "-"} {item.vendedor_id?.apellidos ?? "-"}</Text>
                                    </Col>
                                    <Col xs={24} lg={4} className="center">
                                        <Space size="small" direction="horizontal">
                                        	<ButtonView
                                        		onClick={()=>this.props.navigate(`/admin/clientes/detalle/${item._id}`)}
                                        	/>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
            </>
        )
    }
}
