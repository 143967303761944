import React, { Component, useContext, useEffect, useState } from "react";
import { Row, Col, Modal, Divider, Form, Input, Spin, Typography, List, Result, Button, Space, message } from 'antd';

import PhoneInput from "../Widgets/Inputs/PhoneInput";
// import { SetGuest } from "../../Hooks/Guest";
import "./ModalComprar.scss";
import SelectEstado from "../Widgets/Inputs/SelectEstado";
import SelectPais from "../Widgets/Inputs/SelectPais";
// import  from "antd/lib/skeleton/Paragraph";

// import { Compra } from "../Public/Carrito";
import Carrito, { SetCarrito } from "../../Hooks/Carrito";
import { SetOpenModalCompra, OpenModalCompra } from "../../Hooks/OpenModalCompra";
import { SetCompra, Compra } from "../../Hooks/SorteoCompra";
import { ShopFilled, ShoppingCartOutlined, WhatsAppOutlined } from "@ant-design/icons";
import axios from "axios";

import { BsEnvelope, BsFacebook, BsTelephone } from "react-icons/bs";

const { Title, Text, Paragraph } = Typography

const generateWhatsapp = (mensaje) => {
    //   = `¡Si!. Mi nombre es ${formValues?.nombre} ${formValues?.apellido_paterno} ${formValues?.apellido_materno}, y estoy interesado en el Sorteo ${nombreSorteo} con los boletos: ${this.props?.cart?.map(item => item.numero).join(", ")}`
    return `https://wa.me/15312325998?text=${encodeURIComponent(mensaje)}`
}


/**
 * @class ModalComprar
 * @description Modal para agregar datos importantes del invitado
 */
class ModalComprar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            compra: null,
        }
    }

    formRef = React.createRef();

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {
        this.setState({ loading: true })
        axios.post('/comprar', {
            // ...,
            // sorteo: 
            datos_cliente: values,
            ...this.props.compra,
            boletos: this.props.carrito,
        })
            .then(({ data }) => {
                this.props.setSuccess(true)
                this.props.setCart([])
                this.setState({
                    compra: data
                })
                message.success("Compra realizada con exito")
            })
            .catch((error) => {
                console.log(error)
                message.error("Ocurrio un error al procesar su pago")
            })
            .finally(e => {
                this.setState({ loading: false })
            })
    }

    componentDidMount() {
        document.querySelector('html').classList.add("not-scroll")
    }

    componentWillUnmount() {
        document.querySelector('html').classList.remove("not-scroll")
    }


    render() {

        const BSSocialStyle = {
            textAlign: "center",
            display: "block",
            margin: "auto",
            fontSize: 24
        }

        return (
            <Spin spinning={this.state.loading}>
                {
                    this.props.isSuccess ?
                        <Row>
                            <img src="/fugapadurango-fill.svg" style={{ height: 72, margin: "0 auto 20px auto" }} />
                            <Result
                                status="success"
                                title="¡Recuerda enviarnos tu recibo de pago para confirmar tu compra!"
                                subTitle={
                                    <>
                                        {/* <Title level={3} className="h3-title">Información del Pago</Title> */}

                                        <Title level={5}>Puedes realizar el pago de tu participación en el sorteo a través Zelle o mediante depósito bancario</Title>
                                        <Title level={3}>
                                            <i style={{ fontWeight: "lighter" }}>Número de Folio y Referencia Bancaria:</i> {this.state.compra?.folio_number}
                                        </Title>
                                        <Row>
                                            <Col span={8}>
                                                <a href='tel:+15312325998' target="_blank">
                                                    <BsFacebook style={BSSocialStyle} />
                                                    <span style={{ ...BSSocialStyle, fontSize: 14, }}>Alejandro Hurtado</span>
                                                </a>
                                            </Col>
                                            <Col span={8}>
                                                <a href='tel:+15312325998' target="_blank">
                                                    <BsTelephone style={BSSocialStyle} />
                                                    <span style={{ ...BSSocialStyle, fontSize: 14, }}>+1 (531) 232-5998</span>
                                                </a>
                                            </Col>
                                            <Col span={8}>
                                                <a href='mailto:fugapadurango2023@gmail.com' target="_blank">
                                                    <BsEnvelope style={BSSocialStyle} />
                                                    <span style={{ ...BSSocialStyle, fontSize: 10, }}>fugapadurango2023@gmail.com</span>
                                                </a>
                                            </Col>
                                        </Row>
                                        <Divider />
                                        <Title level={5} style={{ textAlign: "center" }}>FUGA PA' DURANGO LLC</Title>
                                        <Title level={5} style={{ textAlign: "center" }}><i>531-232-5998</i></Title>
                                        <img src="/img/zelle-fuga-pa-durango.svg" alt="FUGA PA' DURANGO LLC" style={{ maxWidth: 200, width: "100%", margin: "auto", display: "block" }} />
                                        <Divider />
                                        <Title level={5} style={{ textAlign: "center" }}>BANCO: BBVA</Title>
                                        <img src="/img/bbva.svg" alt="BBVA" style={{ maxWidth: 200, width: "100%", margin: "auto", display: "block" }} />
                                        <Title level={5} style={{ textAlign: "center", marginTop: "1em" }}>CUENTA: 4152 3141 4002 1174</Title>
                                        <Divider />
                                        <Title level={5} style={{ textAlign: "center" }}>BANCO: SPIN BY OXXO</Title>
                                        <img src="/img/spin.jpeg" alt="SPIN" style={{ maxWidth: 200, width: "100%", margin: "auto", display: "block" }} />
                                        <Title level={5} style={{ textAlign: "center" }}>CUENTA: 4217 4700 2938 6212</Title>
                                    </>
                                }
                                extra={[
                                    // <Button type="primary" key="console">

                                    // </Button>,
                                    <Button key="buy" onClick={this.props.onClose}>Comprar de Nuevo</Button>,
                                ]}
                            />

                        </Row>
                        :
                        <Row gutter={[16, 16]} style={{ justifyContent: 'center' }}>
                            <Col
                                lg={15}
                                md={{
                                    span: 24,
                                    // order: 2
                                }}
                            >
                                <img src="/fugapadurango-fill.svg" style={{ height: 52, marginBottom: 20 }} />
                                <Paragraph>Ingresa tu información para proceder con tu compra</Paragraph>
                                <Form
                                    id="form-cliente"
                                    layout={"vertical"}
                                    onFinish={this.onFinish}
                                    ref={this.formRef}
                                >
                                    <Row justify="center" className="center" gutter={[16, 0]}>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="nombre"
                                                label="Nombre"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingrese el nombre',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="apellido"
                                                label="Apellido"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Ingrese el apellido',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24}>
                                            <Form.Item
                                                name="telefono"
                                                label="Teléfono"
                                                rules={
                                                    [
                                                        {
                                                            required: true,
                                                            message: 'Ingrese el Teléfono',
                                                        },
                                                        {
                                                            pattern: new RegExp(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
                                                            message: 'El teléfono no es valido',
                                                        }
                                                    ]
                                                }
                                            >
                                                <PhoneInput />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="ciudad"
                                                label="Ciudad"
                                                rules={
                                                    [
                                                        {
                                                            required: true,
                                                            message: 'Ingrese la ciudad',
                                                        },
                                                    ]
                                                }
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="codigo_postal"
                                                label="Código Postal"
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Pais"
                                                name="pais_id"
                                                rules={[{
                                                    required: true,
                                                    message: "Seleccione un pais"
                                                }]}
                                            >
                                                <SelectPais
                                                    popupClassName="popup-list"
                                                    onSelect={(pais_id) => {
                                                        this.setState({ pais_id })
                                                        this.formRef.current.setFieldsValue({ estado_id: undefined })
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Estado"
                                                name="estado_id"
                                                rules={[{
                                                    required: true,
                                                    message: "Seleccione un estado"
                                                }]}
                                            >
                                                <SelectEstado
                                                    popupClassName="popup-list"
                                                    pais_id={this.state.pais_id}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Text style={{ opacity: 0.75, marginBottom: 12 }} strong>Ingresa tu correo para recibir información de tu compra</Text>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                label="Correo Electrónico"
                                                name="email"
                                                rules={
                                                    [

                                                        {
                                                            // pattern: new RegExp(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
                                                            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                            message: 'El correo electrónico no es valido',
                                                        }
                                                    ]
                                                }
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col lg={1} md={0}>
                                <div style={{ background: 'rgba(0,0,0,0.08)', height: '100%', width: 1 }} />
                            </Col>
                            <Col
                                lg={8}
                                md={{
                                    span: 24,
                                    // order: 1
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <Title level={4}>Lista de Boletos a Comprar</Title>
                                    <List
                                        className="list-precios"
                                        dataSource={this.props.carrito ?? []}
                                        renderItem={item => {
                                            let digits = String(item.sorteo.cantidad_boletos).length
                                            // 
                                            return <List.Item style={{ display: 'flex', justifyContent: "space-between" }}>
                                                <div >
                                                    <Text style={{ display: "block", fontSize: 10 }}>
                                                        {item.sorteo.nombre}
                                                    </Text>
                                                    <Text strong style={{ display: "block" }}>
                                                        {String(item.numero).padStart(digits, '0')}
                                                    </Text>
                                                </div>
                                                <Text>{item.costo.toLocaleString("en-US", { style: "currency", currency: "USD" })}</Text>
                                            </List.Item>
                                        }}
                                    />
                                    <List.Item style={{ display: 'flex', justifyContent: "space-between" }}>
                                        <Text strong>Total</Text>
                                        <Text>{this.props.carrito.reduce((accumulator, currentItem) => accumulator + currentItem.costo, 0).toLocaleString("en-US", { style: "currency", currency: "USD" })}</Text>
                                    </List.Item>
                                </div>
                            </Col>
                        </Row>
                }
            </Spin>
        )
    }


}



export default function ModalComprarWrapper(props) {

    const { visible = false, onClose = () => { }, cliente_id } = props

    const carrito = useContext(Carrito)
    let setCart = useContext(SetCarrito)

    const compra=useContext(Compra)
    const setCompra=useContext(SetCompra)


    const [isSuccess, setSuccess] = useState(false)

    useEffect(() => {
        setSuccess(false)
    }, [visible])

    const getLink = () => {
        const link = generateWhatsapp(("Hola, quiero comprar los boletos: " + carrito?.map(e => e.numero)?.join(', ') + " del Sorteo: " + compra.nombre ))

        // console.log()
        window.open(link, '_blank');
    }



    return <Modal
        visible={visible}
        onCancel={onClose}
        closable={true}
        destroyOnClose={true}
        width={776}
        zIndex={10}
        footer={
            window.innerWidth > 768 ?
                <Row style={{ width: "100%", justifyContent: "space-between" }}>
                    <Space>
                        <Button

                            onClick={getLink}
                            className="button-whatsapp" icon={<WhatsAppOutlined />} >Hablar por Whatsapp</Button>
                    </Space>
                    <Space>
                        <Button onClick={() => onClose()}>Cerrar</Button>
                        <Button icon={<ShoppingCartOutlined />} type="primary" {...{ form: 'form-cliente', key: 'submit', htmlType: 'submit' }}>Continuar con la Compra</Button>
                    </Space>
                </Row> :
                <Row style={{ display: "block" }} >
                    <Col span={24} style={{ marginBottom: 12 }}>
                        <Button style={{ minWidth: 140 }} icon={<ShoppingCartOutlined />} type="primary" {...{ form: 'form-cliente', key: 'submit', htmlType: 'submit' }}>Continuar con la Compra</Button>
                    </Col>
                    <Col span={24} style={{ marginBottom: 12 }}>
                        <Button

                            onClick={getLink}
                            style={{ minWidth: 200 }} className="button-whatsapp" icon={<WhatsAppOutlined />} >Hablar por Whatsapp</Button>
                    </Col>

                </Row>
        }
        getContainer={() => document.getElementById("root")}
    >
        {/* const carrito = useContext(Carrito)
        let setCart = useContext(SetCarrito) */}
        <ModalComprar
            {...props}
            carrito={carrito}
            setCart={setCart}
            // SetCompra, Compra
            compra={compra}
            setCompra={setCompra}

            setOpenModalCompra={useContext(SetOpenModalCompra)}
            openModalCompra={useContext(OpenModalCompra)}

            isSuccess={isSuccess}
            setSuccess={setSuccess}
        />
    </Modal>

}