import React from 'react'

let Compra = React.createContext(null);
let SetCompra = React.createContext(() => {});

export {
    Compra,
    SetCompra
}

export default Compra;