import React, { useState, useEffect, useContext } from 'react';
import {
    Row,
    Col,
    Button,
    Popconfirm,
    Spin,
    PageHeader,
    Layout,
    message,
    Space,
    Input,
    Form,
    Table,
    Upload,
    Typography,
    Image,
    AutoComplete
} from 'antd';
import axios from 'axios';
import { UploadOutlined, PlusOutlined, InboxOutlined } from "@ant-design/icons";
import Logged from '../../../Hooks/Logged';

const { Content } = Layout;
const { Title, Text } = Typography;

const MAX_ELEMENTS = 10;


function createUrl({ row, type }) {
    const url = new URL(axios.defaults.baseURL + '/item-carousel/' + row?._id);
    url.searchParams.append('type', type);
    url.searchParams.append('name', (type == "xl") ? row?.imagen_xl?.file : row?.imagen_sm?.file);
    url.searchParams.append('Authorization', sessionStorage.getItem('token'));
    return url
}


const AutocompleteWithURL = ({ urlBase, fetchOptions, onChange, value }) => {
    const [loading, setLoading] = useState(false);

    const [sorteos, setSorteos] = useState({
        data: [],
        limit: 10,
        page: 1,

        total: 0,
        pages: 0,
        search: null

    });

    useEffect(() => {
        getSorteos()
    }, [])

    const getSorteos = async ({
        page = sorteos.page,
        limit = sorteos.limit,
        search = sorteos.search
    } = sorteos) => {

        axios.get('/sorteos', {
            params: { limit, page, search }
        })
            .then(({ data }) => {
                setSorteos({ ...sorteos, ...data })
            })
            .catch(res => {
                message.error(res.response.data.message)
            })
    }

    const handleSearch = async (searchText) => getSorteos({ page: 1, search: searchText });

    const handleChange = (inputValue) => onChange(inputValue);

    const handleSelect = (selectedValue) => onChange( `${process.env.REACT_APP_PUBLIC_URL}/evento/${encodeURIComponent(selectedValue)}`); // Genera URL solo al seleccionar;

    return (
        <AutoComplete
            options={sorteos.data.map((item) => ({
                value: item.url,
                key: item._id,
                label: (
                    item.nombre
                    // <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    //     <span>{item.nombre}</span>
                    //     <span style={{ color: '#aaa', fontSize: '12px' }}>ID: {item._id}</span>
                    // </div>
                ),
            }))}
            placeholder="Seleccione o ingrese un URL"
            onSearch={handleSearch}
            onChange={handleChange}
            onSelect={handleSelect}
            value={value}
            allowClear
            notFoundContent={loading ? <Spin size="small" /> : 'Sin resultados'}
            style={{ width: '100%' }}
        >
            <Input />
        </AutoComplete>
    );
};


const EditableCell = ({ editing, dataIndex, title, record, renderFormItem, children, ...restProps }) => {

    // console.log(restProps)
    return (
        <td {...restProps}>
            {editing ? (
                renderFormItem
                    ? renderFormItem({ editing, dataIndex, title, record })
                    :
                    (
                        children
                    )
            ) : (
                children
            )}
        </td>
    );
};


const Carousel = () => {

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [editingKey, setEditingKey] = useState('');

    const [adding, setAdding] = useState(false);

    useEffect(() => {
        list();
    }, []);

    const list = async () => {
        axios.get('/items-carousel')
            .then(({ data }) => {
                setData(data)
            })
            .catch(error => {
                console.log("ERROR, erro", error)
            })
    }

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        let formData = {
            titulo: '',
            descripcion: '',
            escritorio: null,
            telefono: null,
            link: '',
            ...record,
        }

        if (record.imagen_xl) {
            formData.imagen_xl = [
                {
                    uid: formData.imagen_xl?.file,
                    name: formData.imagen_xl?.file,
                    status: 'done',
                    // custom error message to show
                    url: createUrl({ row: record, type: "xl" }),
                },
            ]
        }
        form.setFieldsValue(formData);
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
        if (adding)
            setData(data.slice(0, -1))
        setAdding(false);
    }

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            setLoading(true)
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            form.resetFields()
            const item = newData[index];

            setEditingKey('');

            try {

                const formData = new FormData()
                formData.appendMultiple({
                    ...row,
                    imagen_xl: undefined,
                    imagen_sm: undefined,
                })
                const values = row

                if (values.imagen_xl) {
                    if (values.imagen_xl.file instanceof File)
                        values.imagen_xl = values.imagen_xl.file

                    if (values.imagen_xl instanceof File)
                        formData.append("imagen_xl", values.imagen_xl, values.imagen_xl.name)
                }

                if (values.imagen_sm) {
                    if (values.imagen_sm.file instanceof File)
                        values.imagen_sm = values.imagen_sm.file

                    if (values.imagen_sm instanceof File)
                        formData.append("imagen_sm", values.imagen_sm, values.imagen_sm.name)
                }
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
                let newItem
                if (item._id) {
                    newItem = await axios.put(`/item-carousel/${key}`, formData, config);
                } else {
                    newItem = await axios.post('/item-carousel', formData, config);
                }
                newData.splice(index, 1, { ...item, ...newItem.data });
                setData(newData);
                setLoading(false)
                message.success('Elemento actualizado correctamente');

            } catch (error) {
                setLoading(false)
                console.log("ERROR", error)
            }
        } catch (errInfo) {
            console.error('Error al guardar:', errInfo);
        }
    };

    const handleDelete = async (key) => {
        try {
            await axios.delete(`/item-carousel/${key}`); // Cambiar la ruta según la API
            setData(data.filter((item) => item.key !== key));
            message.success('Elemento eliminado correctamente');
        } catch (error) {
            console.error(error);
            message.error('Error al eliminar el elemento');
        }
    };

    const handleAdd = () => {
        if (data.length >= MAX_ELEMENTS) {
            message.warning('No puedes agregar más de 10 elementos.');
            return;
        }
        const newRow = {
            key: Date.now().toString(),
            titulo: '',
            descripcion: '',
            escritorio: null,
            telefono: null,
            link: '',
        };
        setData([...data, newRow]);
        setEditingKey(newRow.key);
        setAdding(true);
    };

    const mergedColumns = [
        {
            title: 'Título',
            dataIndex: 'titulo',
            editable: true,
            renderFormItem: ({ editing, dataIndex, title, record }) => (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Por favor ingrese ${title}` }]}
                >
                    <Input placeholder="Título" />
                </Form.Item>
            ),
        },
        {
            title: 'Descripción',
            dataIndex: 'descripcion',
            editable: true,
            renderFormItem: ({ editing, dataIndex, title, record }) => (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Por favor ingrese ${title}` }]}
                >
                    <Input placeholder="Descripción" rows={3} />
                </Form.Item>
            ),
        },
        {
            title: 'Imagen de Escritorio',
            dataIndex: 'imagen_xl',
            editable: true,
            render: (item, row) => {
                if (!item) {
                    return <><InboxOutlined style={{ fontSize: 16 }} /> Sin Imagen</>
                } else {
                    return <Image src={createUrl({ row, type: "xl" })} style={{ width: 70, height: 70 }} />
                }
            },
            renderFormItem: ({ editing, dataIndex, title, record }) => (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                >
                    <Upload maxCount={1} beforeUpload={() => false}>
                        <Button icon={<UploadOutlined />}>Subir Imagen</Button>
                    </Upload>
                </Form.Item>
            ),

        },
        {
            title: 'Imagen de Teléfono',
            dataIndex: 'imagen_sm',
            editable: true,
            render: (item, row) => {
                if (!item) {
                    return <><InboxOutlined style={{ fontSize: 16 }} /> Sin Imagen</>
                } else {
                    return <Image
                        src={createUrl({ row, type: "sm" })}
                        style={{ width: 70, height: 70 }} />
                }
            },
            renderFormItem: ({ editing, dataIndex, title, record }) => (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                // valuePropName="fileList"
                // getValueFromEvent={normFile}
                >
                    <Upload maxCount={1} beforeUpload={() => false}>
                        <Button icon={<UploadOutlined />}>Subir Imagen</Button>
                    </Upload>
                </Form.Item>
            ),
        },
        {
            title: 'Link a ',
            dataIndex: 'link',
            editable: true,
            width: 200,
            render: (e, { titulo }) => (e && e != "" && e != null) ? <a href={e} target='_blank'>{titulo}</a> : "N/A",
            renderFormItem: ({ editing, dataIndex, title, record }) => (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Por favor ingrese ${title}` }]}
                >
                    <AutocompleteWithURL />
                </Form.Item>
                // {/* <Input placeholder="Descripción" rows={3} /> */}
            ),
        },
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <Button onClick={() => save(record.key)} type="primary">Guardar</Button>
                        <Button onClick={cancel}>Cancelar</Button>
                    </Space>
                ) : (
                    <Space>
                        <Button disabled={editingKey !== ''} onClick={() => edit(record)}>Editar</Button>
                        <Popconfirm title="¿Estás seguro de eliminar?" onConfirm={() => handleDelete(record.key)}>
                            <Button danger>Eliminar</Button>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ].map((col) => ({
        ...col,
        onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            renderFormItem: col.renderFormItem,
        }),
    }));


    return (
        <Spin spinning={loading}>
            <PageHeader
                className="site-page-header custom-page-header"
                title={
                    <Row>
                        <Col xs={20}>
                            <Title level={3}>Elementos del Carousel</Title>
                        </Col>
                    </Row>
                }
            />
            <Content className="admin-content content-bg pd-1">
                <Form form={form} component={false} autoComplete={"offs"}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={data}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                        footer={() => (
                            <Button
                                type="dashed"
                                onClick={handleAdd}
                                disabled={data.length >= MAX_ELEMENTS}
                                icon={<PlusOutlined />}
                            >
                                Agregar Nuevo Elemento
                            </Button>
                        )}
                    />
                </Form>
            </Content>
        </Spin>
    );
};

export default Carousel;
