import React from 'react';
import { Redirect, Route, useNavigate } from 'react-router-dom';


import User from '../../Hooks/Logged';


/**
 *
 *
 * @const PrivateRoute
 * @description Restringe el acceso a las rutas privadas si no se ha iniciado sesion
 */
const PrivateRoutes = ({children }) => {

    let user = React.useContext(User)
    const navigate = useNavigate();
    
    if(user?.rol_id)
        return (children)
    else
        navigate('/customer/compras')

}
       

export {
    PrivateRoutes
}
