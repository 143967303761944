import React, { Component } from "react";
import { Row, Col, Typography, Spin, PageHeader, Layout, message, Card , Space, Tabs, Breadcrumb} from "antd";
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom";

//Componentes
import { EstatusCliente } from '../../Utils';
import VentasTab from "./Tabs/VentasTabs";
import ModalClientes from "./ModalClientes";
import { CardRifa } from "../../Widgets/Cards";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { ButtonDelete, ButtonView, ButtonEdit, } from "../../Widgets/Buttons";


const { Content } = Layout;
const { Paragraph, Title, Text } = Typography;
const moment = require('moment');

/**
 *
 *
 * @export
 * @class ClientesDetalle
 * @extends {Component}
 * @description Vista donde se ve información detallada del cliente
 */
class ClientesDetalle extends Component {

    /**
     *Creates an instance of ClientesDetalle.
     * @param {*} props
     * @memberof ClientesDetalle
     */
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible_modal: false,
            cliente: { },
            cliente_id: null,
        };
    }

    componentDidMount() {
        
        if(this.props.params.cliente_id)
            this.getCliente()

    }

   
    /**
    * @method getCliente
    * @description Obtiene la informacion de un cliente
    */
    getCliente = () => {
        this.setState({ loading: true })
        axios.get('/cliente/'+this.props.params.cliente_id,{
            params:{
                ventas: true
            }
        })
        .then(({data}) => {
            console.log("data", data);
            this.setState({
                cliente: data
            })
        })
        .catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener la información")
        }).finally(()=>this.setState({loading: false}))
    } 


     /**
    * @method deletecliente
    * @description Elimina un registro de rifas
    */
    deletecliente = (cliente_id) => {
        this.setState({loading: true})
        axios.delete('/cliente/'+cliente_id,{})
        .then(({data}) => {
            message.success("Eliminado con exito")
            this.props.navigate("/admin/clientes")
        }).catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al eliminar el cliente")

        }).finally(()=>this.setState({loading: false}))
    }
   

    render() {

        let { cliente } = this.state

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={<Breadcrumb>
                            <Breadcrumb.Item>
                              <Text underline className="hover" onClick={()=>this.props.navigate('/admin/clientes')}>Clientes</Text>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{cliente.nombre} {cliente.apellido_paterno}</Breadcrumb.Item>
                        </Breadcrumb>}  
                    />
                    <Content className="admin-content content-bg pd-1">
                    	<Row>
                            <Col span={24}>
                                <Card bordered>
                                    <Row align="middle" className='mb-1' gutter={[12, 12]}>
                                        <Col span={12}>
                                            <Title level={4}>Información del cliente</Title>
                                        </Col>
                                        <Col span={12} className="flex-right">
                                            <Space>
                                                <ButtonEdit
                                                    onClick={()=>this.setState({modal_visible: true})}
                                                />
                                                <ButtonDelete
                                                    onConfirm={()=>this.deletecliente(cliente._id)}
                                                />
                                                {EstatusCliente(cliente.estatus)}
                                            </Space>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16,16]}>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Nombre Completo</Paragraph>
                                            <Paragraph strong style={{ fontSize: 14, textAlign: "justify" }}>
                                                {cliente.nombre} {cliente.apellido_paterno} {cliente.apellido_materno ?? ""}
                                            </Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Fecha de Registro</Paragraph>
                                            <Paragraph strong style={{ fontSize: 14, textAlign: "justify" }}>{moment(cliente.createdAt).format("DD/MM/YYYY")}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Email</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16 }}>{cliente.email}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <Paragraph>Teléfono</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16 }}>{cliente.telefono    }</Paragraph>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card bordered className='mt-1'>
                                    <Row gutter={[16,16]}>
                                        <Col xs={24} lg={12} xl={8} className="flex-column">
                                            <Paragraph>Total Vendido</Paragraph>
                                            <Paragraph strong style={{ fontSize: 14, textAlign: "justify" }}>$ {cliente?.monto_vendido?.toMoney(true)} USD</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={8} className="flex-column">
                                            <Paragraph>Boletos Comprados</Paragraph>
                                            <Paragraph strong style={{ fontSize: 14, textAlign: "justify" }}>{cliente.boletos}</Paragraph>
                                        </Col>
                                        <Col xs={24} lg={12} xl={8} className="flex-column">
                                            <Paragraph>Concursos Adquiridos</Paragraph>
                                            <Paragraph strong style={{ fontSize: 16 }}>{cliente.sorteos}</Paragraph>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Tabs defaultActiveKey="1">
                                    <Tabs.TabPane tab="Ventas Realizadas" key="1">
                                        <VentasTab 
                                            cliente_id={this.props.params.cliente_id}
                                            initialValues={{
                                                cliente_id: {
                                                    label: `${cliente.nombre} ${cliente.apellido_paterno}`,
                                                    value: cliente._id
                                                }
                                            }}

                                            getCliente={this.getCliente}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Content>  
                </Spin>
                <ModalClientes
                    visible={this.state.modal_visible}
                    onClose={(flag)=>{
                        this.setState({modal_visible: false})
                        if(flag === true)
                            this.getCliente()
                    }}
                    cliente_id={this.props.params.cliente_id}
                />
               
            </>
        );
    }
}

export default function (props) {

    return <ClientesDetalle {...props} navigate={useNavigate()} params={useParams()}/>
}