import React, { Component, useRef, forwardRef } from "react";
import { Row, Col, Spin, PageHeader, Layout, message, Typography } from "antd";
import axios from "axios"
import { useNavigate } from "react-router-dom";

//Componentes
import ModalPremio from "./ModalPremio";
import { CardPremio } from "../../../Widgets/Cards";
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";

const { Content } = Layout;
const { Title } = Typography;


/**
 *
 *
 * @export
 * @class PremiosTab
 * @extends {Component}
 * @description Vista donde se listan todos los PremiosTab
 */
class PremiosTab extends Component {

    /**
     *Creates an instance of PremiosTab.
     * @param {*} props
     * @memberof PremiosTab
     */
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible_modal: false,
            premios: [],
            proyecto_id: null,
        };
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if(this.props.sorteo_id) {
            this.getSorteo()
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.sorteo_id !== prevProps.sorteo_id) {
            this.getSorteo()
        }
    }

    /**
    * @method getSorteo
    * @description Obtiene la informacion de un sorteo
    */
    getSorteo = () => {
        this.setState({ loading: true })
        axios.get('/sorteo/'+this.props.sorteo_id)
        .then(({data}) => {
            console.log("data", data);
            this.setState({premios: data.premios})
        })
        .catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener la información")
        }).finally(()=>this.setState({loading: false}))
    } 

    /**
    * @method deletePremio
    * @description Elimina el premio relacionado
    */
    deletePremio = (premio_id) => {
        this.setState({ loading: true })
        axios.delete('/premio/'+premio_id)
        .then(({data}) => {
            message.success("Premio eliminado")
            this.getSorteo()
        })
        .catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error el eliminar")
        }).finally(()=>this.setState({loading: false}))
    } 


    render() {
        return (
            <>
        	<Row gutter={[16,16]}>
                {this.state.premios?.length === 0 ? <Col span={24}><Title level={3} className="text-center">No hay ningún premio registrado</Title></Col> : null}
                {this.state.premios?.map(item => <Col sm={12} xl={8} xxl={5}>
                    <CardPremio 
                        {...item}
                        onEdit={()=>this.setState({modal_visible: true, premio_id: item._id})}
                        onDelete={()=>this.deletePremio(item._id)}
                    />
                </Col>)}
            </Row>
            <FloatingButton onClick={()=>this.setState({modal_visible: true})}/>
            <ModalPremio
                visible={this.state.modal_visible}
                onClose={(flag)=>{
                    this.setState({modal_visible: false, premio_id: undefined})
                    this.getSorteo()
                }}
                premio_id={this.state.premio_id}
                sorteo_id={this.props.sorteo_id}
            />
            </>
        );
    }
}

export default  forwardRef((props, ref) => {

    return <PremiosTab {...props}  navigate={useNavigate()} ref={ref}/>
})