import React, { useEffect } from "react";
import { Row, Col, Form, Input } from "antd";
import Quill from "../../../Widgets/Inputs/Quill";
import { ImageDragger } from "../../../Widgets/Uploaders";
import axios from "axios";

export default function DescriptionsForm({ form, sorteo, onFinish }) {


    useEffect(() => {
        if (sorteo && sorteo != null)
            form.setFieldsValue({
                ...sorteo,
                banner: sorteo.banner ? {
                    uid: sorteo.banner.file,
                    name: sorteo.banner.name,


                    file: sorteo.banner.file,
                    name: sorteo.banner.name,
                    path: sorteo.banner.path,


                    status: 'done',
                    fromDB: true,
                    url: `${axios.defaults.baseURL}/sorteo/${sorteo._id}?banner=${sorteo.banner.file}&Authorization=${sessionStorage.getItem("token")}`
                } : null,
            })

    }, [sorteo])


    return (
        <Form
            form={form}
            layout="vertical"
            name="descripciones-banner-form"
            onFinish={onFinish}
        >
            <Row gutter={[16, 4]}>

                {/* Banner del Evento */}
                <Col xs={24} md={24}>
                    <Form.Item
                        label="Banner del Evento"
                        name="banner"
                        rules={[{ required: true, message: "Por favor suba un banner para el evento" }]}
                    >
                        <ImageDragger />
                    </Form.Item>
                </Col>

                {/* Descripción General */}
                <Col xs={24} md={24}>
                    <Form.Item
                        label="Descripción General"
                        name="descripcion"
                        rules={[{ required: true, message: "Por favor ingrese una descripción general del evento" }]}
                    >
                        <Input.TextArea placeholder="Escribe una descripción general del evento" rows={4} />
                    </Form.Item>
                </Col>

                {/* Contenido con Editor Markdown */}
                <Col xs={24} md={24}>
                    <Form.Item
                        label="Contenido"
                        name="contenido"
                        rules={[{ required: true, message: "Por favor ingrese el contenido del evento" }]}
                    >
                        <Quill placeholder="Escribe el contenido del evento con formato Markdown" />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
