import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Popconfirm,
    Spin,
    Form,
    Table,
    Upload,
    Input,
    Image,
    Space,
    Typography,
    message,
    InputNumber,
} from "antd";
import { UploadOutlined, PlusOutlined, InboxOutlined, MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";

const { Title } = Typography;
const MAX_ELEMENTS = 10;

// const createImageUrl = ({ record,  }) => {

//     return url.toString();
// };

function createUrl({ row }) {

    const url = new URL(axios.defaults.baseURL + `/premio/${row._id}`);
    url.searchParams.append("imagen", row?.imagen?.file);
    url.searchParams.append("Authorization", sessionStorage.getItem("token"));
    return url
}



const EditableCell = ({ editing, dataIndex, title, record, renderFormItem, children, ...restProps }) => {

    // console.log(restProps)
    return (
        <td {...restProps}>
            {editing ? (
                renderFormItem
                    ? renderFormItem({ editing, dataIndex, title, record })
                    :
                    (
                        children
                    )
            ) : (
                children
            )}
        </td>
    );
};

export default function PrizesForm({ sorteo }) {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [loading, setLoading] = useState(false);
    const [adding, setAdding] = useState(false);

    useEffect(() => {
        fetchPrizes();
    }, []);

    const fetchPrizes = async () => {
        setLoading(true);
        try {
            const response = await axios.get("/premios", {
                params: {
                    sorteo_id: sorteo._id,
                }
            });
            setData(response.data);
        } catch (error) {
            console.error("Error al obtener premios:", error);
        } finally {
            setLoading(false);
        }
    };

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
        if (adding) setData(data.slice(0, -1));
        setAdding(false);
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            setLoading(true)
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);
            form.resetFields()
            const item = newData[index];

            setEditingKey('');

            try {

                const formData = new FormData()
                formData.appendMultiple({
                    ...row,
                    sorteo_id: sorteo._id,
                    imagen: undefined,
                })
                const values = row
                console.log('values.', values)
                if (values.imagen) {
                    if (values.imagen.file instanceof File)
                        values.imagen = values.imagen.file

                    if (values.imagen instanceof File)
                        formData.append("imagen", values.imagen, values.imagen.name)
                }

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
                let newItem
                if (item._id) {
                    newItem = await axios.put(`/premio/${key}`, formData, config);
                } else {
                    newItem = await axios.post('/premio', formData, config);
                }
                newData.splice(index, 1, { ...item, ...newItem.data });
                setData(newData);
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.log("ERROR", error)
            }
        } catch (errInfo) {
            console.error('Error al guardar:', errInfo);
        }
    };

    const handleDelete = async (key) => {
        try {
            await axios.delete(`/premio/${key}`);
            setData(data.filter((item) => item.key !== key));
            message.success("Premio eliminado correctamente");
        } catch (error) {
            message.error("Error al eliminar el premio");
        }
    };

    const handleAdd = () => {
        if (data.length >= MAX_ELEMENTS) {
            message.warning(`No puedes agregar más de ${MAX_ELEMENTS} premios.`);
            return;
        }
        const newRow = { key: Date.now().toString(), nombre: "", descripcion: "", imagen_escritorio: null, imagen_telefono: null };
        setData([...data, newRow]);
        setEditingKey(newRow.key);
        setAdding(true);
    };

    const columns = [
        {
            title: "Nombre del Premio",
            dataIndex: "nombre",
            editable: true,
            renderFormItem: ({ editing, dataIndex, title, record }) => (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Por favor ingrese ${title}` }]}
                >
                    <Input placeholder="Nombre del premio" />
                </Form.Item>
            ),
            // renderFormItem: () => <Input placeholder="Nombre del premio" />,
        },
        {
            title: "Descripción",
            dataIndex: "descripcion",
            editable: true,
            renderFormItem: ({ editing, dataIndex, title, record }) => (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Por favor ingrese ${title}` }]}
                >
                    <Input placeholder="Descripción" rows={3} />
                </Form.Item>
            ),
            // renderFormItem: () => <Input.TextArea placeholder="Descripción del premio" rows={2} />,
        },
        {
            title: "Costo",
            dataIndex: "costo",
            editable: true,
            renderFormItem: ({ editing, dataIndex, title, record }) => (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Por favor ingrese ${title}` }]}
                >
                    <InputNumber
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                    />
                </Form.Item>
            ),
        },
        {
            title: "Imagen",
            dataIndex: "imagen",
            editable: true,
            render: (item, row) => {
                if (!item) {
                    return <><InboxOutlined style={{ fontSize: 16 }} /> Sin Imagen</>
                } else {
                    return <Image src={createUrl({ row, type: "xl" })} style={{ width: 70, height: 70 }} />
                }
            },
            renderFormItem: ({ editing, dataIndex, title, record }) => (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                >
                    <Upload maxCount={1} beforeUpload={() => false}>
                        <Button icon={<UploadOutlined />}>Subir Imagen</Button>
                    </Upload>
                </Form.Item>
            ),
        },
        {
            title: "Acciones",
            dataIndex: "acciones",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space>
                        <Button onClick={() => save(record.key)} type="primary">
                            Guardar
                        </Button>
                        <Button onClick={cancel}>Cancelar</Button>
                    </Space>
                ) : (
                    <Space>
                        <Button disabled={editingKey !== ""} onClick={() => edit(record)}>
                            Editar
                        </Button>
                        <Popconfirm title="¿Estás seguro de eliminar?" onConfirm={() => handleDelete(record.key)}>
                            <Button danger>Eliminar</Button>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => ({
        ...col,
        onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            renderFormItem: col.renderFormItem,
        }),
    }));

    return (
        <Spin spinning={loading}>
            <Title level={3}>Lista de Premios</Title>
            <Form
                form={form}
                component={false}
            >
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={{ onChange: cancel }}
                    footer={() => (
                        <Button type="dashed" onClick={handleAdd} disabled={data.length >= MAX_ELEMENTS} icon={<PlusOutlined />}>
                            Agregar Nuevo Premio
                        </Button>
                    )}
                />
            </Form>
        </Spin>
    );
}
