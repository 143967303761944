import React, { Component, useContext, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Menu, Typography, Button, Space, Drawer, Badge } from 'antd';
import { HashLink } from 'react-router-hash-link';
import { AiOutlineShoppingCart, AiOutlineMenu } from 'react-icons/ai';
import '../../Styles/Public/header.scss'
import CarritoDrawer from './Carrito';
import { Carrito, } from '../../Hooks/Carrito';
import { SetOpenModalCompra } from '../../Hooks/OpenModalCompra';
import User from '../../Hooks/Logged'

const { Header, Footer, Sider, Content } = Layout;
const { Text } = Typography;

class HeaderPublic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDrawerCarrito: false,
            isScrolling: false,


            isSmallScreen: window.innerWidth <= (this.props.breakpoint || 768), // Usa un breakpoint por defecto de 768px
        }
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("resize", this.handleResize);

    }

    handleResize = () => {
        const { breakpoint = 768 } = this.props;

        this.setState({ isSmallScreen: window.innerWidth <= breakpoint });
        // setIsScreenSmall(window.innerWidth <= breakpoint);
    };

    handleScroll = () => {
        const currentScrollY = window.scrollY;
        const isScrolling = currentScrollY > 0

        if (isScrolling && !this.state.isScrolling) {
            this.setState({ isScrolling: true });
        }

        if (!isScrolling && this.state.isScrolling) {
            this.setState({ isScrolling: false });
        }
    };

    irUltimosSorteos = () => {
        const sorteo = document.getElementById('ultimos-sorteos');
        if (sorteo) window.scroll({
            top: sorteo.getBoundingClientRect().top + window.scrollY,
            left: 0,
            behavior: "smooth",
        })
    }


    openDrawer = () => {
        if (this.props.cart?.length > 0) {
            // this.setState({ openDrawerMenu: true })
            this.props.setOpenModalCompra(true)
        } else {
            this.irUltimosSorteos();
        }
    }


    renderMenu = (props) => {
        return <Menu theme='dark' mode="horizontal" {...props}>
            <Menu.Item key="1">
                <HashLink smooth to={'/#inicio'}>
                    <Text className="text-white">INICIO</Text>
                </HashLink>
            </Menu.Item>
            <Menu.Item key="2">
                <HashLink smooth to='/#nosotros'>
                    <Text className="text-white">NOSOTROS</Text>
                </HashLink>
            </Menu.Item>
            <Menu.Item key="4">
                <HashLink smooth to='/#ultimos-sorteos'>
                    <Text className="text-white">SORTEOS</Text>
                </HashLink>
            </Menu.Item>
            <Menu.Item key="5" >
                <HashLink smooth to='/#ganadores'>
                    <Text className="text-white">GANADORES</Text>
                </HashLink>
            </Menu.Item>
            {/* SetOpenModalCompra */}
            <Menu.Item key="3">
                <HashLink smooth to='/#contacto'>
                    <Text className="text-white">CONTACTO</Text>
                </HashLink>
            </Menu.Item>
            {
                this.state.isSmallScreen && <Menu.Item key="participar-ahora" className='participar'>
                    {/* <HashLink smooth to='/#sorteos'> */}
                    <Button
                        type='primary'
                        className='participar'
                        onClick={this.irUltimosSorteos}
                        // onClick={this.openDrawer}
                    >
                        ¡Participar ahora!
                    </Button>
                    {/* </HashLink> */}
                </Menu.Item>
            }
            {
                this.state.isSmallScreen && <Menu.Item key="comprar">
                    <Badge count={this.state.count}>
                        <Button
                            onClick={this.openDrawer}
                            ghost>
                            <AiOutlineShoppingCart size={20} />
                        </Button>
                    </Badge>
                </Menu.Item>

            }
        </Menu>
    }



    render() {
        // console.log("isScrolling", this.state.isScrolling)
        return (
            <Header
                id={'header-landing'}
                className={`header-landing ${this.state.isScrolling ? "scrolling" : ""}`}
            >
                <Link to={'/'}>
                    <img
                        className="logo-menu"
                        src="/img/logo.svg"
                        alt="Fuga pa´ Hermosillo"
                    />
                </Link>
                {this.renderMenu({ className: "lg-menu" })}
                <div className='buttons-content'>
                    {/* <HashLink smooth to='/#sorteos'> */}
                    <Button
                        type='primary'
                        className='participar'
                        onClick={() => {
                            this.irUltimosSorteos()
                        }}
                    >
                        ¡Participar ahora!
                    </Button>
                    <Badge count={this.props.cart.length}>
                        <Button onClick={() => this.openDrawer()} ghost>
                            <AiOutlineShoppingCart size={20} />
                        </Button>
                    </Badge>
                    <Button
                        className='open-drawer'
                        onClick={() => this.setState({ openDrawerMenu: true  })}
                        ghost
                    >
                        <AiOutlineMenu size={20} />
                    </Button>
                </div>
                <Drawer open={this.state.openDrawerMenu} onClose={() => { this.setState({ openDrawerMenu: false }) }}>
                    {this.renderMenu({
                        className: "drawer-menu width-100",
                        theme: "light",
                        mode: "vertical",
                    })}
                </Drawer>
            </Header>
        );
    }
}

export default function (props) {

    let cart = useContext(Carrito);

    // /Users/albertovirrey/Desktop/fugapadurango/fugapadurango-web0004/src/Hooks/Compra.js

    // let OpenModalCompra = React.createContext(false);
    // let SetOpenModalCompra = React.createContext(() => {});
    let setOpenModalCompra = useContext(SetOpenModalCompra)
    let user = useContext(User)

    return <HeaderPublic cart={cart} setOpenModalCompra={setOpenModalCompra} navigate={useNavigate} user={user} {...props} />

}


