import React from 'react'
import { Route, Routes } from 'react-router-dom'

import PublicRoutes from './Routes/PublicRoutes';
import AdminRoutes from './Routes/AdminRoutes';
import CustomerRouter from './Routes/CustomerRouter';

/**
 * 
 * @function Routes
 * @description Router principal de la app
 * @export
 */
export default function rutas(){

	return (
		<Routes>
			<Route path="/*" element={ <PublicRoutes/>}/>
			<Route path="/admin/*" element={<AdminRoutes />} />
			<Route path="/customer/*" element={<CustomerRouter />} />
		</Routes>
	)
}
