import React, { Component, useEffect } from "react";

import ImgCrop from "antd-img-crop";

import { Upload, Button } from "antd";
import { UploadOutlined, DeleteOutlined, ConsoleSqlOutlined, PlusOutlined, InboxOutlined } from "@ant-design/icons";
import { withSuccess } from "antd/lib/modal/confirm";


function Uploader(props) {

    const {
        children,
        value = props.fileList,
        onChange = () => { },
        updateOne = () => { },

        renderImages = () => {}
    } = props;


    let [fileList, setFileList] = React.useState(value);

    const triggerChange = (changedValue) => {

        let currentFileList = []
        if(fileList)
            currentFileList = [...fileList, changedValue]
        else
            currentFileList = [changedValue]

        if (onChange)
            onChange(currentFileList);

        if(updateOne)
            updateOne(changedValue);

        setFileList(currentFileList);
    }

    useEffect(() => {
        if(fileList?.length != value?.length)
            setFileList(value)
    }, [value])

    return <Upload 
        {...props}
        fileList={fileList}
        customRequest={(custom) => {
            let { file } = custom
            file.status = "done"
            triggerChange(file);
        }}
    >
        {children}
        {/* {renderImages(fileList)} */}
    </Upload>
}


function AvatarLoader(props){


    const {
        value = null,
        onChange = () => {},
        onRemove = () => {},
        _id,
        url,
        remove = false,
        uploaderProps = {},
        imageCrop = false,
        buttonDelete = true,
        uploadContent = <div>
            <UploadOutlined />
            <div className="ant-upload-text">Subir Imagen</div>
        </div>
    } = props;

    let [image, setImage] = React.useState(null);

    const triggerChange = (changedValue) => {
        if (onChange){
            onChange(changedValue)
        }
        setImage(changedValue)       
    };

    const returnFileList = (image) => {
        let list = []

        if(image instanceof File){
            list = [{
                uid: 1,
                url: URL.createObjectURL(image),
                status: 'done',
                name: image?.name
            }]
        }else if(image){
            list = [{
                ...image
            }]
        }

        return list
    }

    useEffect(() => {
        if(value){
            setImage(value)
        }
        
    },[value]);

    const upload = <Upload 
        listType="picture-card"
        showUploadList={true}
        accept="image/*"
        customRequest={(custom) => {
            const { file } = custom;
            triggerChange(file);
        }}
        maxCount={1}
        fileList={returnFileList(image)}
        onRemove={()=>{
            onRemove()
            triggerChange(null);
        }}
    >
        {!image ? uploadContent : null}
    </Upload>


    return imageCrop ? (
        <ImgCrop 
            modalTitle="Editar Imagen"
            modalOk="Guardar"
            modalCancel="Cancelar"
            rotate
        >
            {upload}
        </ImgCrop>
    ) : upload
}


function ImageDragger(props){


    const {
        value = null,
        onChange = () => {},
        onRemove = () => {},
        _id,
        url,
        remove = false,
        uploaderProps = {},
        imageCrop = false,
        buttonDelete = true,
        uploadContent = <div>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Haga click o arrastre el banner del evento</p>
            <p className="ant-upload-hint">
                Para poder indicar el banner del evento, haga click aquí o arrastre la imagen del banner.
            </p>
        </div>
    } = props;

    let [image, setImage] = React.useState(null);

    const triggerChange = (changedValue) => {
        if (onChange){
            onChange(changedValue)
        }
        setImage(changedValue)       
    };

    const returnFileList = (image) => {
        let list = []

        if(image instanceof File){
            list = [{
                uid: 1,
                url: URL.createObjectURL(image),
                status: 'done',
                name: image?.name
            }]
        }else if(image){
            list = [{
                ...image
            }]
        }

        return list
    }

    useEffect(() => {
            console.log("value", value);
        if(value){
            setImage(value)
        }
        
    },[value]);


    let ruta = "/img/recientes-1.png"
    if(image instanceof File) ruta = URL.createObjectURL(image)
    if(image?.url) ruta = image?.url

    const upload = <Upload.Dragger 
        showUploadList={true}
        accept="image/*"
        customRequest={(custom) => {
            const { file } = custom;
            triggerChange(file);
        }}
        maxCount={1}
        fileList={returnFileList(image)}
        onRemove={()=>{
            onRemove()
            triggerChange(null);
        }}
    >
        {!image ? uploadContent : <div className="image-backgroung" style={{ backgroundImage: `url("${ruta}")`}}></div>}
    </Upload.Dragger>


    return imageCrop ? (
        <ImgCrop 
            modalTitle="Editar Imagen"
            modalOk="Guardar"
            modalCancel="Cancelar"
            rotate
        >
            {upload}
        </ImgCrop>
    ) : upload
}

function ImagesUploader(props) {

    const {
        children = <div>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Haga click o arrastre las imagenes</p>
        </div>,
        value = props.fileList,
        onChange = () => { },
        updateOne = () => { },
        onRemove = () => { }
    } = props;


    let [fileList, setFileList] = React.useState(value);

    const triggerChange = (changedValue) => {

        let currentFileList = []
        if(fileList)
            currentFileList = [...fileList, changedValue]
        else
            currentFileList = [changedValue]


        if (onChange)
            onChange(currentFileList);

        if(updateOne)
            updateOne(changedValue);

        setFileList(currentFileList);
    }

    const deleteFile = (e) => {
        let temp = [...fileList]
        let index = temp.findIndex(img => img.uid === e.uid)
        if(index !== -1){
            temp.splice(index,1)
            setFileList(temp)
            if (onChange)
                onChange(temp);
        }
    }

    useEffect(() => {
        if(fileList?.length != value?.length)
            setFileList(value)
    }, [value])

    return <Upload.Dragger 
        //{...props}
        fileList={fileList}
        customRequest={(custom) => {
            let { file } = custom
            file.status = "done"
            triggerChange(file);
        }}
        onRemove={(e)=>{
            onRemove(e)
            deleteFile(e);
        }}
    >
        {children}
    </Upload.Dragger>
}

export {
    Uploader,
    AvatarLoader,
    ImageDragger,
    ImagesUploader
}


