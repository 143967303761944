import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Card, Row, Col, Space, Button} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ButtonDelete, ButtonView, ButtonEdit, } from "../../Widgets/Buttons"

//COMPONENTES
import { CardPaso } from "../../Widgets/Cards";

//MODAL
import ModalPasos from "./ModalPasos";

// fake data generator
const getItems = count =>
	Array.from({ length: count }, (v, k) => k).map(k => ({
		id: `item-${k}`,
		content: `item ${k}`
	}));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: "none",
	margin: `0 0 ${grid}px 0`,

	// change background colour if dragging
	background: isDragging ? "lightgreen" : "transparent",

	// styles we need to apply on draggables
	...draggableStyle
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? "lightblue" : "lightgrey",
	padding: grid,
	width: "100%"
});

class App extends Component {


	constructor(props) {
		super(props);
		this.state = {
			items: []
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	componentDidUpdate(prevProps){
		if(this.props.pasos?.length > 0 && prevProps.pasos.length != this.props.pasos.length){
			this.setState({items: this.props.pasos.map(e=>({...e, id: e._id}))})
		}
	}

	onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(
			this.state.items,
			result.source.index,
			result.destination.index
		);

		this.setState({
			items
		});
	}

	/**
	 * @method onSave
	 * @description Añade o actualiza los valores del array de items
	 * */
	onSave = (values) => {
		let items = [...this.state.items]
		if(!values.id){
			//Se añade una id temporal para que se pueda hacer drag, tambien nos sirve para identificar las imagenes que pertenecen al premio
			//ya que esta se añade a os nombre sde las imagenes, (se hace en onFinish de DrawerRifa)
			values.id = Math.floor(Math.random() * 1000000).toString(16);
			items.push(values)
		}else{
			let index = items.findIndex(e => e.id === values.id)
			if(index !== -1)
				items.splice(index,1,values)
		}
		this.setState({items})

	}

	/**
	 * @method onDelete
	 * @description Elimina un elemento del array de items
	 * */
	onDelete = (index) => {
		let items = [...this.state.items]

		if(index !== -1)
			items.splice(index,1)


		this.setState({items})

	}


	/**
	 * @method onEdit
	 * @description Elimina un elemento del array de items
	 * */
	onEdit = (item) => {
		this.setState({modal_visible: true, paso: item})

	}

	// Normally you would want to split things out into separate components.
	// But in this example everything is just done in one place for simplicity
	render() {
		return (
			<div>
				<Button onClick={()=>this.setState({modal_visible: true})} className="mb-1">Agregar Paso</Button>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
							>
								{this.state.items.map((item, index) => (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style
												)}
											>
												<CardPaso 
													{...item} 
													local={true}
													index={index}
													onEdit={this.onEdit}
													onDelete={this.onDelete}
													sorteo_id={this.props.sorteo_id}
												/>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<ModalPasos
					visible={this.state.modal_visible}
					onClose={()=>{
						this.setState({modal_visible: false, paso: undefined})
					}}
					onSave={this.onSave}
					paso={this.state.paso}
					sorteo_id={this.props.sorteo_id}
				/>
			</div>
		);
	}
}

export default App