import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select, Badge, DatePicker, InputNumber } from "antd";
import { CloseCircleOutlined, LockOutlined, ShoppingOutlined, CheckCircleOutlined, CheckOutlined } from "@ant-design/icons";


import { SelectPais, SelectEstado } from "../../../Widgets/Inputs/Selects";
import { useForm } from "antd/lib/form/Form";
// import { useForm } from "";


const moment = require("moment")

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function GeneralInfoForm({ form, sorteo, onFinish }) {
    const [tipo, setTipo] = useState(null);
    const [paisId, setPaisId] = useState(null);


    useEffect(() => {

        console.log("form", form)
        console.log("X X X X X X", sorteo)

        if (sorteo && sorteo != null)
            form.setFieldsValue({
                ...sorteo,
                
                fechas: (sorteo.fecha_inicio && sorteo.fecha_finalizacion) ? [
                    moment(sorteo.fecha_inicio),
                    moment(sorteo.fecha_finalizacion)
                ] : null,

                pais_id: sorteo.pais_id ? {
                    label: sorteo.pais_id.nombre,
                    value: sorteo.pais_id._id
                } : null,

                estado_id: sorteo.estado_id ? {
                    label: sorteo.estado_id.nombre,
                    value: sorteo.estado_id._id
                } : null,
            })

    }, [sorteo])

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            name="informacion-general-form"
        >
            <Row gutter={[16, 4]}>
                <Col xs={24} md={12}>
                    <Form.Item label="Nombre del Concurso o Rifa" name="nombre" rules={[{ required: true, message: "Por favor indique el nombre del concurso" }]}>
                        <Input
                            onChange={(event) => {
                                form.setFieldsValue({
                                    url:
                                        process.env.REACT_APP_PUBLIC_URL + '/evento/' + event.target.value.trim().replace(/\s+/g, '-').toLowerCase().replace(/[^a-z0-9ñ\-]+/g, ''),
                                });
                            }}
                        />
                    </Form.Item>
                </Col>

                {/* Tipo Sorteo */}
                <Col xs={24} md={6}>
                    <Form.Item label="Tipo Sorteo" name="tipo" rules={[{ required: true, message: "Por favor seleccione un tipo" }]}>
                        <Select onSelect={setTipo}>
                            <Option value={1}>Venta</Option>
                            <Option value={2}>Promoción</Option>
                        </Select>
                    </Form.Item>
                </Col>

                {/* Estatus del Sorteo */}
                <Col xs={24} md={6}>
                    <Form.Item label="Estatus del Sorteo" name="estatus" rules={[{ required: true, message: "Por favor seleccione un estatus" }]}>
                        <Select>
                            <Option value={0}>
                                <Badge color="#f50" text={<><CloseCircleOutlined style={{ color: '#f50', marginRight: 8 }} />Cancelada</>} />
                            </Option>
                            <Option value={1}>
                                <Badge color="#888888" text={<><LockOutlined style={{ color: '#888888', marginRight: 8 }} />Cerrada</>} />
                            </Option>
                            <Option value={2}>
                                <Badge color="#52c41a" text={<><ShoppingOutlined style={{ color: '#52c41a', marginRight: 8 }} />En Venta</>} />
                            </Option>
                            <Option value={3}>
                                <Badge color="#1890ff" text={<><CheckCircleOutlined style={{ color: '#1890ff', marginRight: 8 }} />Vendida</>} />
                            </Option>
                            <Option value={4}>
                                <Badge color="#d32f2f" text={<><CheckOutlined style={{ color: '#00e600', marginRight: 8 }} />Concluida</>} />
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>

                {/* Nombre del Concurso o Rifa */}
                <Col xs={24} md={tipo === 2 ? 12 : 16}>
                    <Form.Item label="URL" name="url">
                        <Input disabled />
                    </Form.Item>
                </Col>


                {/* ID de Facebook */}
                {tipo === 2 && (
                    <Col xs={24} md={4}>
                        <Form.Item label="ID de Facebook" name="facebook_id">
                            <Input />
                        </Form.Item>
                    </Col>
                )}
                {/* Inicio y Finalización del Evento */}
                <Col xs={24} md={8}>
                    <Form.Item label="Inicio y Finalización del Evento" name="fechas" rules={[{ required: true, message: "Por favor defina las fechas del evento" }]}>
                        <RangePicker className="width-100" />
                    </Form.Item>
                </Col>

                {/* Costo del Boleto */}
                <Col xs={24} md={16}>
                    <Form.Item label="Costo del Boleto" name="costo_boleto" rules={[{ required: true, message: "Por favor indique el costo del boleto" }]}>
                        <InputNumber
                            defaultValue={0}
                            className="width-100"
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                    </Form.Item>
                </Col>

                {/* Cantidad de Boletos */}
                <Col xs={24} md={8}>
                    <Form.Item label="Cantidad de Boletos" name="cantidad_boletos" rules={[{ required: true, message: "Por favor indique la cantidad de boletos" }]}>
                        <InputNumber
                            className="width-100"
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                    </Form.Item>
                </Col>

                {/* Dirección / Localización del Evento */}
                <Col xs={24} md={12}>
                    <Form.Item label="Dirección / Localización del Evento" name="direccion">
                        <Input />
                    </Form.Item>
                </Col>

                {/* Ciudad */}
                <Col xs={24} md={5}>
                    <Form.Item label="Ciudad" name="ciudad">
                        <Input />
                    </Form.Item>
                </Col>

                {/* País */}
                <Col xs={24} md={4}>
                    <Form.Item label="País" name="pais_id" rules={[{ required: true, message: "Seleccione un país" }]}>
                        <SelectPais onSelect={(pais_id) => {
                            setPaisId(pais_id);
                            form.setFieldsValue({ estado_id: undefined });
                        }} />
                    </Form.Item>
                </Col>

                {/* Estado */}
                <Col xs={24} md={3}>
                    <Form.Item label="Estado" name="estado_id" rules={[{ required: true, message: "Seleccione un estado" }]}>
                        <SelectEstado pais_id={paisId} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
