import { Button, Col, Layout, Row, Spin, Typography, message, Image, Card } from 'antd'
import React, { Component } from 'react'

import { FaSquareFull } from 'react-icons/fa'
import Icon from '@ant-design/icons'
import { Link } from 'react-router-dom'


import axios from "axios"
import Slider from 'react-slick'
// import Slider from 'react-slick/lib/'



import { CarroIcon, ChevronLeft, ChevronRight, FlechasDecorativas } from '../Widgets/Iconos'
import Recientes from './Recientes'

import Footer from './Footer'
import Header from './Header'
import ComoParticipar from './Sections/ComoParticipar'
import { HashLink } from 'react-router-hash-link'

// import "animate.css/animate.min.css";
// import WOW from 'wowjs'

import AOS from "aos";
import "aos/dist/aos.css";

import '../../Styles/Public/landing.scss'

const { Content } = Layout
const { Paragraph, Title, Text } = Typography


class Landing extends Component {

	// Slider methods
	next() {
		this.slider.slickNext()
	}

	previous() {
		this.slider.slickPrev()
	}

	constructor(props) {
		super(props)
		this.state = {
			ventas: 0,
			sorteos: 0,
			ganadores: 0,
			concluidos: 3,
			rifas: {
				data: []
			},
			slide: 0,
			width: 0,
			height: 0,

			isMobile: null,

			currentIndex: 0,
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);

		this.updateDimensions()
		// this.setState({ width: window.innerWidth, height: window.innerHeight })
		this.getConters()
		this.getRifas()
		this.getCarousels()
		this.getPrecioMinimo()
		AOS.init({
			once: true,
			offset: 200,
			delay: 100,
			duration: 800,
			easing: "ease-in-out", 
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	/**
	 * @static
	 * @memberof Landing
	 *
	 * @method coungetContersters
	 * @description Obtiene informacion de las ventas
	 * */
	getConters = () => {
		axios.get('/landing/counters')
			.then(response => {
				// console.log("response", response.data);
				this.setState({
					ventas: response.data.ventas,
					sorteos: response.data.sorteos,
					ganadores: response.data.ganadores,
					concluidos: response.data.concluidos,
				})
			})
			.catch(error => {

			})
	}

	getRifas = () => {
		axios.get('/landing/sorteos')
			.then(({ data }) => {
				this.setState({
					rifas: data
				})

			})
			.catch(error => {
				message.error("Error al obtener los sorteos")
			})
	}

	getCarousels = () => {
		axios.get('/landing/carousels')
			.then(({ data }) => {
				// console.log("carousels", data);

				this.setState({
					carousels: data
				})
			})
			.catch(error => {
				message.error("Error al obtener los carousels")
			})
	}

	getPrecioMinimo = () => {
		axios.get('/landing/precio-minimo')
			.then(({ data }) => {
				// console.log("carousels", data);

				this.setState({
					precio_minimo: data
				})
			})
			.catch(error => {
				message.error("Error al obtener los carousels")
			})
	}

	updateDimensions = () => {

		if (this.state.isMobile == null) {
			this.setState({ isMobile: window.innerWidth < 992 })
			// return this.setState({ isMobile: true })
		}

		if (window.innerWidth < 992 && !this.state.isMobile) {
			return this.setState({ isMobile: true })
		}

		if (window.innerWidth > 992 && this.state.isMobile) {
			return this.setState({ isMobile: false })
		}
		// this.setState({ width: window.innerWidth, height: window.innerHeight })
	}

	createUrl = ({ row, type }) => {
		const url = new URL(axios.defaults.baseURL + '/item-carousel/' + row._id);
		url.searchParams.append('type', type);
		url.searchParams.append('Authorization', sessionStorage.getItem('token'));
		return url
	}


	renderTitle = (row) => {

		// let title = 
		// let subtitle = 

		const content = [
			// class="wow slideInLeft" data-wow-duration="2s" data-wow-delay="5s">
			<Title data-aos="fade-up" className='slider-title '>{row.titulo}</Title>,
			<Title data-aos="fade-up" data-aos-delay="200" className='slider-description'>{row.descripcion}</Title>,
		];

		if (row.link && row.link != null) {
			return <a href={row.link} target='_blank'>
				{content}
				<Button data-aos="fade-up" data-aos-delay="300" style={{ marginTop: '1em' }} className='button-sorteos' type='primary' size="large">Ir al sorteo</Button>
			</a>
		} else {
			return content
		}
	}

	render() {

		const { rifas, slide, carousels } = this.state

		const sliderSettings = {
			dots: true,
			infinite: true,
			speed: 200,
			autoplaySpeed: 1500,
			// dela

			autoplay: true,

			slidesToShow: 1,
			slidesToScroll: 1
		}


		return (
			<Layout id={'landing'} className="transparent landing">
				<Header />

				<section id='inicio'>
					<Slider
						arrows={true}
						ref={ref => this.slider = ref}
						afterChange={index => this.setState({ slide: index })}
						className='slider'
						{...sliderSettings}
					>
						{
							(Array.isArray(this.state.carousels) && this.state.carousels?.length > 0) ?
								this.state.carousels.map((row, index) => <div key={'slider-item-' + index} className={'slider-item '}>
									<img
										className='slider-background'
										src={this.createUrl({
											row,
											type: this.state.isMobile ? "sm" : "xl"
										})}
										alt='auto-premio' />
									<div className='slider-shadow' />
									<div className='slider-content'>
										<div className='slider-content-wrapper'>
											{this.renderTitle(row)}

										</div>
									</div>
								</div>)
								: null
						}
					</Slider>
					{/* <div className='slider-buttons'>
						<Typography.Link
							onClick={() => {
								console.log("this.slider", this.slider)
								this.slider.slickPrev()
								window.x = this.slider
							}}

						>
							<Icon
								component={ChevronLeft}
							/>
						</Typography.Link>
						<Typography.Link
							onClick={() => {
								this.slider.slickNext()
							}}
						>
							<Icon component={ChevronRight} />
						</Typography.Link>
					</div> */}
				</section>

				<section id='nosotros' className='us'>
					<img className='circle' src='/bgicons/circle.svg' />
					<img className='dots' src='/bgicons/dots.svg' />
					<img className='escorpio' src='/bgicons/escorpio.svg' />
					<div className='us-header'>
						<Text
							className='us-header-title'
							data-aos="fade-up"
						// data-aos-delay="200"
						// data-wow-duration="2s"
						// data-wow-delay="1s"
						>PARA GANAR SOLAMENTE</Text>
						<img
							data-aos="fade-up"
							data-aos-delay="200"
							className='us-header-image animate__animated animate__fadeInUp ' src='/fugapadurango-orange.svg' />
					</div>
					<div className='us-content'>
						<div className='us-wrapper'>
							<p className='us-description'
								data-aos="fade-right"
								data-aos-delay="300"
							>
								En Fuga pa Durango, tienes la oportunidad de ganar premios y dinero solamente adquiriendo participaciones según la dinámica del sorteo.
							</p>
							<img src='/ticket.svg' height={100} />
						</div>
						<img
							data-aos="fade-left"

							data-aos-delay="300"
							className='us-description-image' src='/img/sorteoimagen1.png' />
					</div>
				</section>

				<section className='ribbon'>
					<div className='ribbon-logo'>
						<img className='ribbon-logo-image' src='/escorpio-white.svg' alt='Fuga pa Durango' />
					</div>
					<Text className='ribbon-title'>PARTICIPA DESDE ${this.state.precio_minimo ?? 0} USD</Text>
					<HashLink to={'/#ultimos-sorteos'}>
						<Button className='ribbon-button'>¡Comprar Ahora!</Button>
					</HashLink>
				</section>

				<section id='ultimos-sorteos' className='latest-sorteos'>
					<Title
						data-aos="fade-up"

						className='latest-sorteos-title'>ULTIMOS <span className='orange'>SORTEOS</span></Title>
					<Paragraph
						data-aos="fade-up"
						data-aos-delay="200"
						className='latest-sorteos-description'>En Fuga pa Durango, tienes la oportunidad de ganar premios y dinero solamente adquiriendo participaciones según la dinámica del sorteo.</Paragraph>
					<Recientes />
				</section>
				<ComoParticipar style={{marginBottom: 100}} />
				<section className='ganadores' id='ganadores'>
					<div className='ganadores-header'>
						<div>
							<Title>Nuestros Ganadores</Title>
							<Paragraph>¡Tu puedes ser nuestro proximo ganador!</Paragraph>
						</div>
						<HashLink to={'/#ultimos-sorteos'}>
							<Button className='button-sorteos' type='primary' size="large">Ver todos los sorteos</Button>
						</HashLink>
					</div>
					<Slider
						arrows={true}
						ref={c => (this.slider = c)}
						afterChange={index => this.setState({ slide: index })}
						className='winners'
						infinite
						{...{
							dots: true,
							infinite: false,
							speed: 500,
							slidesToShow: 1,
							slidesToScroll: 1,
							// slidesToShow: 3,
							// slidesToScroll: 3,
							initialSlide: 0,
							// responsive: [
							// 	{
							// 		breakpoint: 1024,
							// 		settings: {
							// 			slidesToShow: 3,
							// 			slidesToScroll: 3,
							// 			infinite: true,
							// 			dots: true
							// 		}
							// 	},
							// 	{
							// 		breakpoint: 600,
							// 		settings: {
							// 			slidesToShow: 2,
							// 			slidesToScroll: 2,
							// 			initialSlide: 2
							// 		}
							// 	},
							// 	{
							// 		breakpoint: 480,
							// 		settings: {
							// 			slidesToShow: 1,
							// 			slidesToScroll: 1
							// 		}
							// 	}
							// ]
						}}

					>
						{
							[
								{
									image: "/img/ganadores/sorteo-datsun.jpeg"
								},
								// {
								// 	image: "/img/ganadores/sorteo-datsun.jpeg"
								// },
								// {
								// 	image: "/img/backgroung/bg-login-stars.png"
								// },
								// {
								// 	image: "/img/backgroung/bg-login-stars.png"
								// },
								// {
								// 	image: "/img/backgroung/bg-login-stars.png"
								// },
								// {
								// 	image: "/img/backgroung/bg-login-stars.png"
								// },
								// {
								// 	image: "/img/backgroung/bg-login-stars.png"
								// },
								// {
								// 	image: "/img/backgroung/bg-login-stars.png"
								// },
								// {
								// 	image: "/img/backgroung/bg-login-stars.png"
								// },
							].map(e => <Image style={{width: "100%", objectFit: 'contain'}}  className='winners-slide' src={e.image} alt='auto-premio' />)
						}
					</Slider>
				</section>
				<Footer />
			</Layout>
		)
	}
}

export default function () {

	return <Landing />
}