import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "antd";
// import { Uploader } from "../../../Widgets/Uploaders";

import axios from 'axios'

import { Upload, Modal, Button, Image, } from 'antd';
import { PlusOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
const ImageUploadField = ({ value = [], onChange, maxCount = 5, ...props }) => {
    const [previewImage, setPreviewImage] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);

    // Manejar cambios en el fileList
    const handleChange = ({ fileList }) => {
        onChange?.(fileList);
    };

    // Mostrar vista previa
    const handlePreview = (file) => {
        if (file.originFileObj) {
            const objectUrl = URL.createObjectURL(file.originFileObj);
            setPreviewImage(objectUrl);
        } else {
            setPreviewImage(file.thumbUrl || file.url);
        }
        setPreviewOpen(true);
    };

    // Botón de carga
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Subir Imagen</div>
        </div>
    );

    return (
        <>
            <Upload
                listType="picture-card"
                fileList={value}
                onChange={handleChange}
                onPreview={handlePreview}
                beforeUpload={() => false} // Desactiva el upload automático
                maxCount={maxCount}
                {...props}
            >
                {value.length < maxCount && uploadButton}
            </Upload>

            {previewImage && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(null),
                    }}
                    src={previewImage}
                />
            )}
        </>
    );
};

export default function ImagesForm({ form, sorteo, onFinish }) {

    useEffect(() => {

        console.log("form", form)
        console.log("X X X X X X", sorteo)

        if (sorteo && sorteo != null)
            form.setFieldsValue({
                ...sorteo,
                ...sorteo,

                imagenes_escritorio: Array.isArray(sorteo.imagenes_escritorio) ? sorteo.imagenes_escritorio.map(imagen_celular => ({
                    uid: imagen_celular.file,

                    file: imagen_celular.file,
                    name: imagen_celular.name,
                    path: imagen_celular.path,
                    
                    status: 'done',
                    fromDB: true,
                    url: `${axios.defaults.baseURL}/sorteo/${sorteo._id}?imagen=${imagen_celular.file}&Authorization=${sessionStorage.getItem("token")}`
                })) : null,
                
                imagenes_celular: Array.isArray(sorteo.imagenes_celular) ? sorteo.imagenes_celular.map(imagen_celular => ({
                    uid: imagen_celular.file,

                    file: imagen_celular.file,
                    name: imagen_celular.name,
                    path: imagen_celular.path,


                    status: 'done',
                    fromDB: true,
                    url: `${axios.defaults.baseURL}/sorteo/${sorteo._id}?imagen=${imagen_celular.file}&Authorization=${sessionStorage.getItem("token")}`
                })) : null,
            })

    }, [sorteo])

    return (
        <Form onFinish={onFinish} layout="vertical" form={form} name="images-form">
            <Row gutter={[16, 4]}>

                {/* Imágenes para Escritorio */}
                <Col xs={24} md={20}>
                    <Form.Item
                        label="Imágenes para Escritorio"
                        name="imagenes_escritorio"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
                        rules={[{ required: true, message: "Por favor suba al menos una imagen para escritorio" }]}
                    >
                        <ImageUploadField multiple={true} />
                    </Form.Item>
                </Col>

                {/* Imágenes para Teléfono */}
                <Col xs={24} md={20}>
                    <Form.Item
                        label="Imágenes para Teléfono"
                        name="imagenes_celular"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
                        rules={[{ required: true, message: "Por favor suba al menos una imagen para teléfono" }]}
                    >
                        <ImageUploadField multiple={true} />
                    </Form.Item>
                </Col>

            </Row>
        </Form>
    );
}
