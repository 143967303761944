import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, InputNumber } from 'antd';

//componentes
import { AvatarLoader } from "../../Widgets/Uploaders";
import axios from "axios"
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        if(this.props.paso){
            this.formRef.current.setFieldsValue({
                ...this.props.paso,
                imagen: this.props.paso.imagen ? {
                    uid: this.props.paso.imagen.file,
                    name: this.props.paso.imagen.name,
                    status: 'done',
                    fromDB: true,
                    url: `${axios.defaults.baseURL}/sorteo/${this.props.sorteo_id}?imagen=${this.props.paso.imagen.file}&Authorization=${sessionStorage.getItem("token")}`
                } : null
            })
        }
    }

    formRef = React.createRef();

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {

        this.props.onSave({...values, id: this.props?.paso?.id, _id: this.props?.paso?._id})
        this.props.onClose()    
        
    }


    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-premio"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">

                        <Col span={18} className="center">

                            <Form.Item
                                name="imagen"
                                label="Imagen"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Selecciona una imagen',
                                    },
                                ]}
                            >
                                <AvatarLoader 
                                    
                                />
                            </Form.Item>
                        </Col>
                        <Col span={18}>

                            <Form.Item
                                name="titulo"
                                label="Titulo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el titulo',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={18}>

                            <Form.Item
                                name="descripcion"
                                label="Descripción"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese los apellidos',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, paso } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={paso ? "Editar Paso" : "Crear Paso"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-premio', key: 'submit', htmlType: 'submit' }}
    >
        <ModalUsuarios  {...props} />
    </Modal>

}