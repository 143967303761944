import React, { Component } from 'react';
import { Row, Col, Card, Button, Popconfirm, Spin, PageHeader, Layout, message, Space, List, Typography } from 'antd'
import axios from 'axios';
import { Link,useNavigate } from "react-router-dom";
import { DeleteOutlined, EditOutlined, InfoOutlined, FilterOutlined } from "@ant-design/icons";


//COMPONENTES
import Logged from '../../../Hooks/Logged'
import { ButtonView } from "../../Widgets/Buttons";
import FiltrosDrawer from '../../Widgets/DrawerFiltros/DrawerFiltros';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { EstatusCliente } from "../../Utils";


import ModalClientes from "./ModalClientes"

const { Content } = Layout;
const { Text, Title } = Typography;

/**
 * @export
 * @class Roles
 * @extends {Component}
 * @description Vista de Roles
 */
export class Roles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,

            rol_id: undefined,
            clientes: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
                filters: []
            },
            searching: true,
            filtroSearch: '',

        }
    }

    componentDidMount() {
        this.getClientes()
    }


    /**
     *
     *
     * @memberof Roles
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
     componentDidUpdate(prevProps) {

        if (this.props.search !== prevProps.search) {
            this.getClientes();
        }
    } 

    /**
     * @methodOf  Roles
     * @method getClientes
     *
     * @description Obtiene las Roles
     * */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.props.search,
        filters = this.state.clientes.filters
    } = this.state.clientes) => {

        console.log('filters', filters)
        this.setState({ loading: true })
        axios.get('/clientes', {
            params: {
                page,
                limit, 
                search,
                filters
            }
        }).then(({ data }) => {
            console.log("data", data);

            this.setState({
                clientes: data
            });

        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }

    render() {
        return (
            <Spin spinning={false}>
            <PageHeader 
                className="site-page-header custom-page-header" 
                title={<Row>
                    <Col xs={20}>
                        <Title level={3}>Clientes</Title>
                    </Col>
                    <Col xs={4} className="flex-right">
                        <Button
                            type="dashed"
                            onClick={() => this.setState({ visibleFilters: true })}
                            className="button-plus"
                            icon={<FilterOutlined />} />
                    </Col>
                </Row>}
            />
            <Content className="admin-content content-bg pd-1">
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Clientes " }}
                    dataSource={this.state.clientes.data}
                    pagination={{
                        current: this.state.clientes.page,
                        pageSize: this.state.clientes.limit,
                        total: this.state.clientes.total,
                        position: 'bottom',
                        className: "flex-left",
                        onChange: (page, limit) => this.getClientes({ page, limit })
                    }}
                    header={<Row className="component-list-header width-100 pr-1 pl-1"  gutter={[16, 16]}>
                        <Col span={8} className="center">
                            <Text strong>Nombre</Text>
                        </Col>
                        <Col span={3} className="center">
                            <Text strong>Telefono</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text strong>Email</Text>
                        </Col>
                        <Col span={4} className="center">
                            <Text strong>Vendedor</Text>
                        </Col>
                        <Col span={2} className="center">
                            <Text strong>Estatus</Text>
                        </Col>
                    </Row>}

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100 " gutter={[16, 16]}>

                                    <Col span={8} className="center">
                                        <Text ellipsis>{item.nombre} {item.apellido_paterno} {item.apellido_materno ?? ""}</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text>{item.telefono ?? "-"}</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text ellipsis>{item.email ?? "-"}</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text>{item.vendedor_id?.nombre ?? "-"} {item.vendedor_id?.apellidos ?? "-"}</Text>
                                    </Col>
                                    <Col span={2} className="center">
                                        {EstatusCliente(item.estatus)}
                                    </Col>
                                    <Col span={3} className="center">
                                        <Space size="small" direction="horizontal">
                                        	<ButtonView
                                        		onClick={()=>this.props.navigate(`/admin/clientes/detalle/${item._id}`)}
                                        	/>
                                            <Button
                                                type="primary"
                                                //disabled={!this.props.editRoles}
                                                icon={<EditOutlined />}
                                                title="Editar"
                                                onClick={() => this.setState({ modal_visible: true, cliente_id: item._id })}
                                            />
                                            <Popconfirm
                                                placement="topRight"
                                                //disabled={!item.editable || !this.props.deleteRoles}
                                                title="¿Deseas eliminar este cliente?"
                                                onConfirm={() => axios.delete('/cliente/'+item._id)
                                                	.then(() => {
                                                        this.getClientes()
                                                        message.success('Cliente eliminado')
                                                    }).catch(error => {
                                                        message.error(error.response.data.message ?? "Error al eliminar el cliente")
                                                    })}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button
                                                    //disabled={!item.editable || !this.props.deleteRoles}
                                                    type="primary"
                                                    icon={<DeleteOutlined />}
                                                    title="Eliminar"
                                                    danger
                                                />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />

            </Content>

           <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modal_visible: true })} />

           	<ModalClientes
           		visible={this.state.modal_visible}
           		onClose={(flag)=>{
           			this.setState({modal_visible: false, cliente_id:  undefined})
           			if(flag === true)
           				this.getClientes()
           		}}
           		cliente_id={this.state.cliente_id}
          	/>

            <FiltrosDrawer
                title="Filtrar Clientes"
                visible={this.state.visibleFilters}
                updateFilters={filters => this.getClientes({
                    page: 1,
                    filters
                })}
                onClose={() => this.setState({ visibleFilters: false })}
                estatusCliente={true}

            />
            
        </Spin>
        )
    }
}

export default function (props) {

    let user = React.useContext(Logged)
    
    /* let permissions = usePermissions(user?.rol_id?.permisos, {
        editRoles: ["roles", "edit"],
        createRoles: ["roles", "create"],
        deleteRoles: ["roles", "delete"]
    }) */
    //console.log("Contexto Logged",permissions)

    return <Roles {...props} navigate={useNavigate()}  />

}