import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, InputNumber } from 'antd';

//componentes
import PhoneInput from "../../../Widgets/Inputs/PhoneInput";
import { ImagesUploader } from "../../../Widgets/Uploaders";
import axios from "axios"
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        if(this.props.premio_id){
            this.getPremio()
        }
        if(this.props.premio){
            this.formRef.current.setFieldsValue({...this.props.premio})
        }
    }

    formRef = React.createRef();

    /**
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = (values) => {

        if(!values.imagenes || values.imagenes?.length === 0 )
            return message.error("El premio debe de tener por lo menos una imagen")
        

        if(this.props.local === true){
            this.props.onSave({...values, id: this.props?.premio?.id, _id: this.props?.premio?._id})
            this.props.onClose()
            return
        }

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            imagenes: undefined,
            premio_id:this.props.premio_id,
            sorteo_id:this.props.sorteo_id
        })

            console.log("values.imagenes", values.imagenes);
        if(values.imagenes?.length > 0){
            for(let img of values.imagenes){
                if(!img.fromDB){
                    formData.append("imagenes", img, img.name)
                }
            }
        }

        if(this.props.premio_id)
            this.updatePremio(formData)
        else
            this.addPremio(formData)
        
    }

    /**
    * @method addPremio
    * @description Añado un premio relacionado al sorteo
    */
    addPremio = (values) => {
        this.setState({loading: true})
        axios.post('/premio',values)
        .then(response => {
            message.success("Premio registrado")
            this.props.onClose(true)
        })
        .catch(error => {
            message.error(error?.response?.data?.message ?? "Error al registrar el premio")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @method updatePremio
    * @description Añado un premio relacionado al sorteo
    */
    updatePremio = (values) => {
        this.setState({loading: true})
        axios.put('/premio/'+this.props.premio_id,values)
        .then(response => {
            message.success("Premio actualizado")
            this.props.onClose(true)
        })
        .catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al registrar el premio")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @method getPremio
    * @description Obtiene informacion de un premio
    */
    getPremio = (values) => {
        this.setState({loading: true})
        axios.get('/premio/'+this.props.premio_id,values)
        .then(({data}) => {
            this.formRef.current.setFieldsValue({
                ...data,
                imagenes: data.imagenes?.length > 0 ? data.imagenes.map(img =>({
                    uid: img.file,
                    name: img.name,
                    status: 'done',
                    fromDB: true,
                    url: `${axios.defaults.baseURL}/premio/${this.props.premio_id}?imagen=${img.file}&Authorization=${sessionStorage.getItem("token")}`
                })) : null,
            })
        })
        .catch(error => {
            message.error(error?.response?.data?.message ?? "Error al obtener el premio")
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-premio"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">

                        <Col span={18}>

                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="descripcion"
                                label="Descripción"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese los apellidos',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="costo"
                                label="Costo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el costo',
                                    },
                                ]}
                            >
                                <InputNumber 
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : 0}
                                />
                            </Form.Item>

                            <Form.Item
                                name="imagenes"
                                label="Imagenes"
                            >
                                <ImagesUploader 
                                    onRemove={(e)=> {
                                        console.clear()
                                        console.log("e", e);
                                        if(this.props.premio_id){
                                            axios.put('/premio/'+(this.props.premio_id), {
                                                imagen: e.file ?? e.uid,
                                            }).catch(error => {
                                                console.log("error", error);
                                                
                                            })
                                        }
                                    }}
                                />
                            </Form.Item>
                            
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, premio_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={premio_id ? "Editar Premio" : "Crear Premio"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-premio', key: 'submit', htmlType: 'submit' }}
    >
        <ModalUsuarios  {...props} />
    </Modal>

}