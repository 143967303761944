import React from 'react';
import { Drawer, Form, Select, Cascader, message, Badge, Button, DatePicker, Space } from 'antd';
import axios from 'axios';

//COMPONENTES
import CAvatar from '../Avatar/Avatar';
import { DownloadOutlined } from '@ant-design/icons';
import { SelectUsuarios } from '../Inputs/Selects'

const { Option } = Select
const { RangePicker } = DatePicker

/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información de la vista en la que se implemente
 */
export default class FiltrosDrawer extends React.Component {

    /**
     *
     *
     * @export
     * @class FiltrosDrawer
     * @extends {React.Component}
     * 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state cuentas Objeto para paginar y buscar en el select de cuentas 
     * @state ordenes Objeto para paginar y buscar en el select de ordenes
     * @state ordenes_compras Objeto para paginar y buscar en el select de ordenes de compra
     * @state areas Objeto para paginar y buscar en el select de areas  
     * @state areas_dictionary Diccionario de Areas. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state rubros_dictionary Diccionario de Rubros. Ya que el select solo nos da el ID, utilizamos este diccionario para obtener el objeto  
     * @state empresas Objeto para paginar y buscar en el select de Empresas
     * @state clasificadores Objeto para paginar y buscar en el select de Clasificadores 
     * 
     * @state paginator.data Lista de elementos para paginar
     * @state paginator.dictionary Ya que los selects funcionan solo con ID´s, utilizamos el diccionario para obtener el objeto al que el ID hace referencia
     * @state paginator.page Pagina actual
     * @state paginator.limit Limit de elementos por página
     * @state paginator.pages Total de paginas
     * @state paginator.total Total de Elementos en la lista
     * @state paginator.search Para buscar
     * @state paginator.search Loading, para declarar el actualizado.
     */
    state = {

        clientes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        sorteos: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false
        },

        fechas: {
            dictionary: {
                fecha: []
            }
        }
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.clientes === undefined || this.props.clientes !== false)
            this.getClientes()
        if (this.props.sorteos === undefined || this.props.sorteos !== false)
            this.getSorteos()

    }



    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getClientes
    * 
    * @description Obtenemos los proveedores
    */
    getClientes = ({ page, limit, search } = this.state.clientes, { clientes } = this.state) => {

        clientes.loading = true;
        clientes.page = page;
        clientes.limit = limit;
        this.setState({ clientes })

        axios.get('/clientes', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                clientes.data = (page === 1) ? response.data.data : [...clientes.data, ...response.data.data];


                clientes.dictionary = {}
                clientes.data.map(d => clientes.dictionary[d._id] = d)

                clientes.total = response.data.total
                clientes.pages = response.data.pages
                clientes.loading = false;

                this.setState({ clientes })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.clientes.loading = false
                    return state
                })
            })
    }

    /**
    *
    *
    * @param {*} [{ page, limit, search }=this.state.proveedores]
    * @param {*} [{ proveedores }=this.state]
    * @memberof FiltrosDrawer
    * @method getClientes
    * 
    * @description Obtenemos los proveedores
    */
    getSorteos = ({ page, limit, search } = this.state.sorteos, { sorteos } = this.state) => {

        sorteos.loading = true;
        sorteos.page = page;
        sorteos.limit = limit;
        this.setState({ sorteos })

        axios.get('/sorteos', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                sorteos.data = (page === 1) ? response.data.data : [...sorteos.data, ...response.data.data];


                sorteos.dictionary = {}
                sorteos.data.map(d => sorteos.dictionary[d._id] = d)

                sorteos.total = response.data.total
                sorteos.pages = response.data.pages
                sorteos.loading = false;

                this.setState({ sorteos })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.sorteos.loading = false
                    return state
                })
            })
    }

    /**
     *
     *
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = ({ fechas, sorteos, clientes, usuarios, boletos, estatus }) => {

        let filtros = []

        //Metodo para añadir al areglo de filtros.
        let addToFilter = (name, objectName, array) => array.map(element => filtros.push({
            _id: element,//clasificador,
            name: name,
            objectName,

        }))

        if (sorteos) addToFilter("sorteo_id", "sorteos", sorteos)
        if (clientes) addToFilter("cliente_id", "clientes", clientes)
        if (usuarios) addToFilter("vendedor_id", "usuarios", usuarios)
        if (boletos) {
            filtros.push({ boletos: boletos })
        }
        if (estatus != undefined) {
            // addToFilter("estatus", "estatus", estatus)
            filtros.push({ 
                _id: estatus,//clasificador,
                name: 'estatus',
                estatus,
             })
        }


        if (fechas) {
            filtros.push({ fechas: fechas })
        }

        if (this.props.updateFilters) {
            this.props.updateFilters(filtros)
        }
    }



    render() {

        const { onClose, visible } = this.props
        const { clientes, sorteos } = this.state
        return (<Drawer
            title={this.props.title}
            placement="right"
            onClose={onClose}
            visible={visible}
            extra={
                <Space>
                    {this.props.extra}
                    {(this.props.csv !== undefined) &&
                        <Button
                            onClick={this.props.downloadCSV}
                            icon={<DownloadOutlined />}
                        >CSV</Button>}
                </Space>
            }>


            <Form
                //ref={ref => this.formFiltros = ref}
                layout='vertical'
                onValuesChange={(x, values) => {
                    console.log("values", values);

                    this.submit(values)
                }}
                onFinish={this.submit}
            >
                {/* RangePicker */}

                {this.props.fechas !== undefined ? <Form.Item label="Rango de Fechas" name="fechas" >
                    <RangePicker
                        placeholder={["Fecha Inicial", "Fecha Final"]}
                        className="width-100"
                    />
                </Form.Item> : null}
                {(this.props.clientes !== undefined) ?
                    <Form.Item label="Clientes" name="clientes">
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getClientes({ search, page: 1 })}
                        >
                            {clientes.data.map(cliente =>
                                <Option key={cliente._id} value={cliente._id}> {cliente.nombre} {cliente.apellido_paterno}</Option>)}
                        </Select>
                    </Form.Item> : null}

                {(this.props.sorteos !== undefined) ?
                    <Form.Item label="Sorteos" name="sorteos">
                        <Select
                            className="width-100"
                            allowClear
                            mode="multiple"
                            showSearch
                            filterOption={false}
                            onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                            onSearch={search => this.getSorteos({ search, page: 1 })}
                        >
                            {sorteos.data.map(sorteo =>
                                <Option key={sorteo._id} value={sorteo._id}>
                                    {sorteo.nombre}</Option>)}
                        </Select>
                    </Form.Item> : null}

                {this.props.usuarios !== undefined ?
                    <Form.Item label="Usuarios" name="usuarios">
                        <SelectUsuarios
                            allowClear
                            mode="multiple"
                        />
                    </Form.Item> : null}
                {this.props.estatus !== undefined ?
                    <Form.Item label="Estatus de Venta" name="estatus">
                        <Select
                            className="width-100"
                            allowClear
                            mode='tags'
                            options={[
                                {
                                    value: 0,
                                    label: "No se completo la transacción",
                                },
                                {
                                    value: 1,
                                    label: "Pendiente",
                                },
                                {
                                    value: 2,
                                    label: "Cancelado",
                                },
                                {
                                    value: 3,
                                    label: "Completado",
                                },
                                {
                                    value: 4,
                                    label: "Por confirmar",
                                }
                            ]}
                        />
                    </Form.Item> : null}

                {this.props.estatusCliente !== undefined ?
                    <Form.Item label="Estatus de Venta" name="estatus">
                        <Select
                            className="width-100"
                            allowClear
                            // mode='tags'
                            options={[
                                {
                                    value: 0,
                                    label: "Perdido",
                                },
                                {
                                    value: 1,
                                    label: "Registrado",
                                },
                                {
                                    value: 2,
                                    label: "Contactado",
                                },
                                {
                                    value: 3,
                                    label: "Ha Comprado",
                                },
                                {
                                    value: 4,
                                    label: "Pagado",
                                }
                            ]}
                        />
                    </Form.Item> : null}

                {this.props.boletos !== undefined ?
                    <Form.Item label="Boletos" name="boletos">
                        <Select
                            className="width-100"
                            allowClear
                            mode='tags'
                            placeholder='Ingrese el boleto y seleccione'
                        />
                    </Form.Item> : null}





            </Form>
        </Drawer >)
    }
};
