import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select, InputNumber, Typography, Space } from 'antd';

//componentes
import PhoneInput from "../../../Widgets/Inputs/PhoneInput";
import { ImagesUploader } from "../../../Widgets/Uploaders";
import axios from "axios"


const { Option } = Select;
const { Text } = Typography


/**
 * @class ModalGanadores
 * @description Modal para el CRUD de Usuarios
 */
class ModalGanadores extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            premios: [],
            boletos: {
                data: [],
                limit: 50,
                page: 1,

                total: 0,
                pages: 0,
            },
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        this.getPremios()
        this.getBoletos()
    }

    formRef = React.createRef();

    /**
    * @method getSorteo
    * @description Obtiene la informacion de un sorteo
    */
    getPremios = () => {
        this.setState({ loading: true })
        axios.get('/sorteo/'+this.props.sorteo_id)
        .then(({data}) => {
            console.log("data", data.premios);

            let ganadores = data.premios.map(item => ({
                    premio_id: item._id,
                    nombre: item.nombre,
                    imagen: item.imagenes?.length > 0 ? `${axios.defaults.baseURL}/premio/${item._id}?imagen=${item.imagenes[0].file}&Authorization=${sessionStorage.getItem("token")}` : "./img/no-image.jpg"
                }))

            this.formRef.current.setFieldsValue({
                ganadores: ganadores
            })

            this.setState({
                premios: ganadores
            })
        })
        .catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener la información")
        }).finally(()=>this.setState({loading: false}))
    }

    /**
    * @method getBoletos
    * @description Obtiene los boletos comprados de un sorteo
    */
    getBoletos = ({
        page = this.state.boletos.page,
        limit = this.state.boletos.limit,
        search
    } = this.state.boletos) => {

        axios.get('/boletos',{
            params:{
                sorteo_id: this.props.sorteo_id,
                limit: limit,
                page: page,
                search,
                clientes: true
            }
        })
        .then(({data}) => {
            console.log("data", data);
            this.setState({boletos: data})
        })
        .catch(error => {
            console.log("error", error);
            message.error(error?.response?.data?.message ?? "Error al obtener la información")
        })
    }


    onFinish = (values) => {
        this.saveGanadores(values)

    }

    /**
    * @method saveGanadores
    * @description Guarda el registro de los ganadores
    */
    saveGanadores = (values) => {
        this.setState({loading: true})
        axios.post("/sorteo/ganadores",{
            ...values,
            sorteo_id: this.props.sorteo_id
        }).then(response => {
            message.success("Ganadores Registrados")
            this.props.onClose(true)
        }).catch((error) => {
            message.error("Error al registrar los ganadores")
        }).finally(()=>this.setState({loading: false}))
    }


    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-premio"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">

                        <Col span={20}>

                            <Form.List 
                                name="ganadores"
                            >
                                {(fields, { add, remove }) => (

                                    <>
                                        {fields.map(({ key, name,  ...restField }) => {
                                            return <Row
                                                key={key}
                                                className="mb-1"
                                            >   
                                                <Col span={10} className="flex-column">
                                                    <Text strong>{this.state.premios[key].nombre}</Text>
                                                    <div className="card-image-sm mb-1" style={{ backgroundImage: `url("${this.state.premios[key].imagen}")`, width: "150px"}} >
                                                    </div>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'premio_id']}    
                                                        hidden
                                                    >
                                                    </Form.Item>
                                                </Col>
                                                <Col span={14} className="center">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'boleto_id']}
                                                        className="m-0"
                                                        style={{width: "70%"}}
                                                    >
                                                        <Select 
                                                            allowClear
                                                            showSearch
                                                            filterOption={false}
                                                            onSearch={search => this.getBoletos({ search, page: 1 })}
                                                            placeholder="Boleto Ganador"
                                                            className="width-100"
                                                        >
                                                            {this.state.boletos.data.map(e => <Option value={e._id} >
                                                                {e.numero} / <small> {e.cliente_id?.nombre} {e.cliente_id?.apellido_paterno}</small>
                                                            </Option> )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        })}
                                    </>
                                )}
                            </Form.List>
                        </Col>
                            
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, usuario } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={"Generar Ganadores"}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-premio', key: 'submit', htmlType: 'submit' }}
        width={800}
    >
        <ModalGanadores  {...props} />
    </Modal>

}