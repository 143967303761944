import React from 'react'
import { Route, Routes } from "react-router-dom";


import Carousel from '../Components/Admin/Carousel/Carousel';

/**
 * 
 * @export
 * @function RouterUsuarios
 * @description Router for Usuarios routes 
 */
function RouterCarousel(props) {
  return (
    <Routes>
      <Route path="" element={<Carousel {...props} />} />
    </Routes>
  )
}

export default RouterCarousel