import React from 'react'
import { Route, Routes } from "react-router-dom";
import Compras from '../../Components/Customer/Compras';

/**
 * 
 * @export
 * @function RouterCompras
 * @description Router for Compras routes 
 */
function RouterCompras(props) {
  return (
    <Routes>
      <Route path='/' element={<Compras />}/>
    </Routes>
  )
}

export default RouterCompras