import React, { Component, useContext, useState, useEffect } from 'react'
import { Layout, Row, Col, Typography, Button, Avatar, Image, Card, Divider, Tabs, Space, message, Collapse } from 'antd'
import { useParams } from 'react-router-dom'
import Slider from 'react-slick'
import axios from 'axios'
import Icon from '@ant-design/icons'

import Header from './Header'
import Footer from './Footer'
import { ChevronLeft, ChevronRight } from '../Widgets/Iconos'
import Boletos from './Boletos'
import ModalComprar from './ModalComprar'
// import Guest from "../../Hooks/Guest";
import User from "../../Hooks/Logged";
import ComoParticipar from './Sections/ComoParticipar'

import { SetOpenModalCompra, OpenModalCompra } from '../../Hooks/OpenModalCompra'

import '../../Styles/Public/evento_detalles.scss'
import { HashLink } from 'react-router-hash-link'
import Aos from 'aos'

const { Panel } = Collapse

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;


function createUrl(premio) {
	const url = new URL(axios.defaults.baseURL + '/sorteo/premio/' + premio._id);
	return url
}

function createUrlImagePremio(row) {
    const url = new URL(axios.defaults.baseURL + `/landing/premio/${row._id}`);
    return url
}


const { Content } = Layout
const { Text, Paragraph, Title } = Typography


const Countdown = ({ targetDate }) => {

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	function calculateTimeLeft() {
		const difference = new Date(targetDate) - new Date();
		if (difference > 0) {
			return {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}
		return { days: 0, hours: 0, minutes: 0, seconds: 0 };
	}

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer); // Cleanup timer on unmount
	}, [targetDate]);

	return (
		<div className="counter">
			<div className="counter-container">
				<span className="counter-label-time">{String(timeLeft.days).padStart(2, "0")}</span>
				<span className="counter-label">Días</span>
			</div>
			<div className='counter-divider' />
			<div className="counter-container">
				<span className="counter-label-time">{String(timeLeft.hours).padStart(2, "0")}</span>
				<span className="counter-label">Horas</span>
			</div>
			<div className='counter-divider' />
			<div className="counter-container">
				<span className="counter-label-time">{String(timeLeft.minutes).padStart(2, "0")}</span>
				<span className="counter-label">Minutos</span>
			</div>
			<div className='counter-divider' />
			<div className="counter-container">
				<span className="counter-label-time">{String(timeLeft.seconds).padStart(2, "0")}</span>
				<span className="counter-label">Segundos</span>
			</div>
		</div>
	);
};


class Landing extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			visible_modal: false,
			visible: false,
			sorteo: {
				disponibles: 0,
				cantidad_boletos: 0,
				banner: '/img/slider/slider1.png',
				descripciones: [null],
			},
			proyecto_id: null,
			toggleCarrito: false,
			width: 0,
			height: 0,
			currentIndex: 0,
		};
	}

	// Slider methods
	next() {
		this.slider.slickNext()
	}

	previous() {
		this.slider.slickPrev()
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		console.log(this.props)
		this.setState({ width: window.innerWidth, height: window.innerHeight })
		this.getInfo()
		Aos.init({
			offset: 300, // Distancia del viewport para activar animaciones
			delay: 100,  // Retraso global
			duration: 1000, // Duración global
			easing: "ease-in-out", // Curva de animación
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight })
	}

	getInfo() {
		axios.get('/info/' + this.props.params.unique_url)
			.then(({ data }) => {
				this.setState({ sorteo: data })
			})
			.catch(error => {
				console.log("error", error);
				message.error(error?.response?.data?.message ?? "Error al obtener la información")
			}).finally(() => this.setState({ loading: false }))
	}


	renderSlides = () => {

		const { sorteo } = this.state
		let items = [
			{
				titulo: sorteo?.nombre,
				descripcion: sorteo?.descripcion,
				imagen: (sorteo?.banner) ? `${axios.defaults.baseURL}/info/${sorteo._id}/banner` : {}
			}
		]
		return items.map((imagen, index) => <div key={'slider-event-item-' + index} className={'slider-event-item '}>
			<img className='slider-event-background' src={imagen?.imagen} alt='auto-premio' />
			<div className='slider-event-shadow' />
			<div className='slider-event-content'>
				<div className='slider-event-content-wrapper'>
					<Title className='slider-event-title'>{imagen.titulo}</Title>
					<Title className='slider-event-description'>{imagen.descripcion}</Title>
				</div>
			</div>
		</div>)
	}

	render() {

		const sliderSettings = {
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 4000,
		}

		return (
			<Layout className="transparent event-details">

				<Header toggleCarrito={this.state.toggleCarrito} sorteoActual={this.state.sorteo?.nombre} />
				<section className='event-details' id='inicio'>
					<Slider
						arrows={true}
						ref={c => (this.slider = c)}
						afterChange={index => this.setState({ slide: index })}
						className='slider-event'
						{...sliderSettings}
					>
						{this.renderSlides()}

					</Slider>
					<div className='slider-event-buttons'>
						<Typography.Link>
							<Icon component={ChevronLeft} onClick={this.previous.bind(this)} />
						</Typography.Link>
						<Typography.Link onClick={this.next.bind(this)}>
							<Icon component={ChevronRight} />
						</Typography.Link>
					</div>
					{this.state.sorteo.fecha_finalizacion && <Countdown targetDate={this.state.sorteo.fecha_finalizacion} />}

				</section>
				<section className='como-participar-evento' id='inicio'>
					<img className='circle circle-right' src='/bgicons/circle-fill.svg' />
					<img className='circle circle-left' src='/bgicons/circle-fill.svg' />
					<ComoParticipar background={false} />
				</section>
				<section className='premios'>
					<img src='/bgicons/irregular.svg' className='irregular' />
					{(Array.isArray(this.state.sorteo?.premios) && this.state.sorteo?.premios.length > 0) && <div className='premios-content'>
						<div className='premios-header'>
							<Title level={3} className='premios-title'>
								<div style={{ position: 'relative', top: -2 }} className='orange-square section-title' /> PREMIOS
							</Title>
							<Title className='premios-title section-title'>¿Que puedes <span className='orange'>ganar?</span></Title>
							<Title level={3} className='premios-subtitle'>Al participar, tienes la posibilida de ganar cualquiera de los productos a continuación:</Title>
						</div>
						<Row className='premios-list' gutter={[16, 16]}>
							{this.state.sorteo.premios.map(premio =>
								<Col span={8} className='premio-item'>
									<Image
										className='premio-item-image'
										src={createUrlImagePremio(premio)} //'/img/recientes-1.png'
									/>
									<Typography>
										<Title level={3}>{premio.nombre}</Title>
										<Paragraph level={5}>{premio.descripcion}</Paragraph>
									</Typography>
								</Col>)}
						</Row>
					</div>}
				</section>
				<section className='boletos'>
					<div className='boletos-content'>
						<div className='boletos-header'>
							<Title level={3} className='premios-title'>
								<div style={{ position: 'relative', top: -2 }} className='orange-square section-title' /> PREMIOS
							</Title>
							<Title className='premios-title section-title'>¡Adquiere tus <span className='orange'>boletos!</span></Title>
							<Title level={3} className='premios-subtitle'>Al participar, tienes la posibilida de ganar cualquiera de los productos a continuación:</Title>
						</div>
						<Boletos
							sorteo_id={this.state?.sorteo?._id}
							sorteo={this.state?.sorteo}
							total={this.state.sorteo.cantidad_boletos}
							costo={this.state.sorteo.costo_boleto}
						/>
						<Button
							style={{ margin: '32px auto 12px auto', display: "block" }}
							size='large'
							className="continuar-compra"

							onClick={() => {
								this.props.setOpenModalCompra(true)
							}}
						>Continuar con mi Compra</Button>
					</div>
				</section>
				<section className='faq'>
					<div className='faq-header'>
						<Title className='premios-title section-title'>
							¿Tienes preguntas?
							<br /><span className='orange'>¡Nosotros respuestas!</span>
						</Title>
					</div>
					<Collapse className='' defaultActiveKey={['1']} >
						<Panel header="¿De dónde son?" key="1">
							<Paragraph>Estamos radicados en 6052 L st Omaha Nebraska, Estados Unidos 68117</Paragraph>
						</Panel>
						<Panel header="Cómo compro los boletos?" key="2">
							<Paragraph>Debes ir a la <HashLink to='/#ultimos-sorteos'>Lista de Sorteos</HashLink>, selecciona tu sorteo, y selecciona tus boletos</Paragraph>
						</Panel>
						<Panel header="¿Cómo se eligen a los ganadores?" key="3">
							<Paragraph>Todos nuestros sorteos se realizan en la fecha especificada en las redes sociales de Fuga Pa Durango</Paragraph>
							<Paragraph>
								El participante ganador del sorteo será el participante cuyo número de boleto coincida con números seleccionados en el live de redes sociales, así mismo,
								el boleto ganador
								debe estar completamente liquidado, la totalidad del pago debe verse reflejado en nuestras cuentas antes del
								comienzo del sorteo, si por alguna razón el pago ha sido devuelo, retenido o cualquier otro motivo, se tomará
								como inválido y no podrá ser acreedor a los premios.
							</Paragraph>
						</Panel>
						<Panel header="¿Qué sucede cuando sale ganador un número no vendido?" key="3">
							<Paragraph>Se vuelve a sortear con la misma dinámica.</Paragraph>
							<Paragraph>
								¡Tendrás mas posibilidades de ganar!
							</Paragraph>
						</Panel>
					</Collapse>
				</section>
				<Footer />
			</Layout>
		)
	}
}

export default function Vista() {
	// let guest = useContext(Guest)
	let user = useContext(User)
	// SetOpenModalCompra, OpenModalCompra
	let setOpenModalCompra = useContext(SetOpenModalCompra)
	let openModalCompra = useContext(OpenModalCompra)

	return <Landing
		params={useParams()}
		user={user}

		setOpenModalCompra={setOpenModalCompra}
		openModalCompra={openModalCompra}
	/>
}