import React, { Component } from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';

import './App.css'
import './Styles/Global/global.css'
import './Styles/global.scss'

// import 'antd/dist/antd.variable.min.css';
import './Styles/Theme/Public/rifas-theme.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// @import 'animate.css';

import { User, SetUser } from './Hooks/Logged';
import Routes from './Routes';
import { CookiesProvider } from 'react-cookie';
import esES from 'antd/es/locale/es_ES';

axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE
axios.defaults.withCredentials = true
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.response.use(function (response) {
  // console.log("response?.headers", response?.headers)
  if (response?.headers?.authorization) {
    sessionStorage.setItem("token", response?.headers?.authorization)
  }
  return response
}, function (error) {
  return Promise.reject(error);
})

axios.interceptors.request.use(function (config) {
  config.headers.Authorization = sessionStorage.getItem("token")
  return config;
})

class App extends Component {

  

  constructor(props) {
    super(props);

    this.state = {
      user: null,
    }
  }

  componentDidMount() {
    axios.get('/user/logged', {
      headers: { Authorization: sessionStorage.getItem('token') }
    }).then(({ data }) => {
      this.setUser(data.data)
    }).catch((error) => {
      console.log('error', error.response)
    })
  }

  setUser = (user) => {
    this.setState({ user })
  };

  render() {
    const { setUser } = this;
    const { user } = this.state;

    ConfigProvider.config({
      theme: {
        primaryColor: '#f26513',
        infoColor: '#f26513',
        borderRadiusBase: '8px',

      },
      
    })


    return (
      <ConfigProvider locale={esES}>
        <BrowserRouter>
          <CookiesProvider>
            <User.Provider value={user}>
              <SetUser.Provider value={setUser}>
                <Routes />
              </SetUser.Provider>
            </User.Provider>
          </CookiesProvider>

        </BrowserRouter>
      </ConfigProvider>

    );
  }
}

export default App;
