import React from 'react'
import { Route, Routes } from "react-router-dom";


import Rifas from '../Components/Admin/Rifas/Rifas';
import RifaDetalle from '../Components/Admin/Rifas/RifaDetalle';

/**
 * 
 * @export
 * @function RouterRifas
 * @description Router for Rifas routes 
 */
function RouterRifas(props) {
  return (
    <Routes>
      <Route path="" element={<Rifas {...props}/>}/>
      <Route path="/detalle/:sorteo_id" element={<RifaDetalle {...props}/>}/>
    </Routes>
  )
}

export default RouterRifas