import React, { Component, useEffect, useRef, useState } from "react";
import { Row, Col, Drawer, Form, Input, message, Spin, Select, DatePicker, InputNumber, Upload, Space, Button, Divider, Modal, Image, Typography } from 'antd';
import axios from "axios"
import { UploadOutlined } from '@ant-design/icons';
import { AiOutlineMinusCircle } from "react-icons/ai";

//componentes
import PasosDrag from "./PasosDrag";
import PremiosDrag from "./PremiosDrag";
import Quill from "../../Widgets/Inputs/Quill";
import { Uploader } from "../../Widgets/Uploaders";
import { SelectPais, SelectEstado } from "../../Widgets/Inputs/Selects";
import Paragraph from "antd/lib/typography/Paragraph";


const { Option } = Select;
const { Dragger } = Upload;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const moment = require('moment');




function ImagePreviewer({ src }) {
    const [visible, setVisible] = useState(false);
    const [scaleStep, setScaleStep] = useState(0.5);

    useEffect(() => {

        if (src)
            setVisible(true)
        else
            setVisible(false)

    }, [src])

    return <Image
        style={{ display: 'none' }}
        src={src}
        preview={{
            visible,
            src, //: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
            onVisibleChange: (value) => {
                setVisible(value);
            },
        }}
    />
};

/**
 * @class DraweRifa
 * @description Drawer para el CRUD de Rifas
 */
class DrawerImagenes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
            premios: [],
            pasos: [],
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')

        if (this.props.sorteo_id) {
            this.getSorteo()
        }


    }

    formRef = React.createRef();


    submit = () => {
        this.formRef.current.submit()
    }


    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit en el formulario, formatea la informacion para que pueda ser leida en el server
   */
    onFinish = (values) => {

        try {


            console.log('values onFinish', values);
            // return 


            const formData = new FormData()
            formData.appendMultiple({
                descripciones: JSON.stringify(values.descripciones),
                imagenes_celular: undefined,
                imagenes_escritorio: undefined
            })

            if (values.imagenes_escritorio) {
                for (const imagen of values?.imagenes_escritorio) {
                    console.log("imagen", imagen);
                    if (imagen.originFileObj)
                        formData.append("imagenes_escritorio", imagen.originFileObj, imagen.name)
                    else
                        formData.append("imagenes_escritorio", imagen)
                }
            }

            if (values.imagenes_celular) {
                for (const imagen of values?.imagenes_celular) {
                    console.log("imagenes_celular", imagen);
                    if (imagen.originFileObj)
                        formData.append("imagenes_celular", imagen.originFileObj, imagen.name)
                    else
                        formData.append("imagenes_celular", imagen)
                }
            }


            if (this.props.sorteo_id)
                this.updateSorteo(formData)


        } catch (e) {
            console.log("Error al realizar submit", e)
        }
    }



    /**
    * @method updateSorteo
    * @description Añade un nuevo registro de rifas
    */
    updateSorteo = (values) => {
        this.setState({ loading: true })
        axios.put('/sorteo/' + this.props.sorteo_id, values)
            .then(response => {
                message.success("Actualizado con exito")
                this.props.onClose(true)
            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al actualizar")
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method getSorteo
    * @description Obtiene la informacion de un sorteo
    */
    getSorteo = () => {
        this.setState({ loading: true })
        axios.get('/sorteo/' + this.props.sorteo_id)
            .then(({ data }) => {
                console.log("data", data);
                this.formRef.current.setFieldsValue({
                    ...data,
                    fechas: [moment(data.fecha_inicio), moment(data.fecha_finalizacion)],
                    banner: data.banner ? {
                        uid: data.banner.file,
                        name: data.banner.name,
                        status: 'done',
                        fromDB: true,
                        url: `${axios.defaults.baseURL}/sorteo/${this.props.sorteo_id}?banner=${data.banner.file}&Authorization=${sessionStorage.getItem("token")}`
                    } : null,
                    pais_id: data.pais_id ? {
                        label: data.pais_id.nombre,
                        value: data.pais_id._id
                    } : null,
                    estado_id: data.estado_id ? {
                        label: data.estado_id.nombre,
                        value: data.estado_id._id
                    } : null,

                    imagenes_celular: Array.isArray(data.imagenes_celular) ? data.imagenes_celular.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/sorteo/" + this.props.sorteo_id + "?imagen=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : [],

                    imagenes_escritorio: Array.isArray(data.imagenes_escritorio) ? data.imagenes_escritorio.map((file, index) => ({
                        uid: file.file,
                        name: file.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/sorteo/" + this.props.sorteo_id + "?imagen=" + file.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : [],

                })

                this.setState({
                    premios: data.premios,
                    pasos: data.pasos,
                    tipo: data.tipo,
                })
            })
            .catch(error => {
                console.log("error", error);
                message.error(error?.response?.data?.message ?? "Error al obtener la información")
            }).finally(() => this.setState({ loading: false }))
    }


    handleWarning = async (values) => {

        console.log(await this.formRef.current.validateFields())
        this.formRef.current.validateFields()
        this.onFinish(values)

    }

    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout={"vertical"}
                    ref={this.formRef}
                    onFinish={this.handleWarning}
                    name="my-form"
                    className="mb-3"
                >
                    <Row justify="center" gutter={[16, 4]}>
                        <Col xs={24} md={20}>
                            <Form.Item
                                label="Imagenes Escritorio"
                                name="imagenes_escritorio"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}
                                rules={[{
                                    validator: (_, value) => {
                                        console.log("value", value);
                                        console.log("other value", this.formRef.current.getFieldValue("imagenes_celular").length);

                                        if (value.length !== this.formRef.current.getFieldValue("imagenes_celular").length) {
                                            return Promise.reject("Las secciones deben de tener la misma cantidad de imagenes");
                                        }

                                        return Promise.resolve();
                                    }
                                }]}
                            >
                                <Uploader
                                    {...this.props.sorteo_id ? {
                                        method: "PUT",
                                        name: "imagenes_escritorio",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        action: axios.defaults.baseURL + '/transacciones',
                                        onRemove: e => {

                                            console.log('e', e);
                                            axios.put('/sorteo/' + this.props.sorteo_id, {
                                                id: this.props.sorteo_id,
                                                imagenes_escritorio: e.uid,
                                            })
                                        }
                                    } : {}}
                                    listType="picture-card"
                                    beforeUpload={e => {
                                        console.log('e', e)
                                        return false
                                    }}
                                    previewFile={async (file, ...args) => {

                                        console.log('e', file, args);
                                        // return file;
                                        return new Promise((resolve, reject) => {

                                            var reader = new FileReader()

                                            reader.readAsDataURL(file)

                                            reader.onload = function () {
                                                console.log(reader)
                                                resolve(encodeURI(reader.result))
                                            }
                                            reader.onerror = function (error) {
                                                reject(error)
                                                console.log('Error: ', error)
                                            };
                                        })
                                    }}
                                    onPreview={(file) => this.setState({ imagePreview: encodeURI(file.thumbUrl) })}
                                >
                                    <div>
                                        <UploadOutlined />
                                        <br />
                                        Subir Imagenes
                                    </div>
                                    <ImagePreviewer
                                        src={this.state.imagePreview}

                                    />
                                </Uploader>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={20}>
                            <Form.Item
                                label="Imagenes Celular"
                                name="imagenes_celular"
                                valuePropName="fileList"
                                getValueFromEvent={e => {
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}
                                rules={[{
                                    validator: (_, value) => {
                                        console.log("value", value);
                                        console.log("other value", this.formRef.current.getFieldValue("imagenes_escritorio").length);

                                        if (value.length !== this.formRef.current.getFieldValue("imagenes_escritorio").length) {
                                            return Promise.reject("Las secciones deben de tener la misma cantidad de imagenes");
                                        }

                                        return Promise.resolve();
                                    }
                                }]}
                            >
                                <Uploader
                                    {...this.props.sorteo_id ? {
                                        method: "PUT",
                                        name: "imagenes_celular",
                                        headers: {
                                            authorization: sessionStorage.getItem("token")
                                        },
                                        action: axios.defaults.baseURL + '/transacciones',
                                        onRemove: e => {

                                            console.log('e', e);
                                            axios.put('/sorteo/' + this.props.sorteo_id, {
                                                id: this.props.sorteo_id,
                                                imagenes_celular: e.uid,
                                            })
                                        }
                                    } : {}}
                                    listType="picture-card"
                                    beforeUpload={e => {
                                        console.log('e', e)
                                        return false
                                    }}
                                    previewFile={async (file, ...args) => {

                                        console.log('e', file, args);
                                        // return file;
                                        return new Promise((resolve, reject) => {

                                            var reader = new FileReader()

                                            reader.readAsDataURL(file)

                                            reader.onload = function () {
                                                console.log(reader)
                                                resolve(encodeURI(reader.result))
                                            }
                                            reader.onerror = function (error) {
                                                reject(error)
                                                console.log('Error: ', error)
                                            };
                                        })
                                    }}
                                    onPreview={(file) => this.setState({ imagePreview: encodeURI(file.thumbUrl) })}
                                >
                                    <div>
                                        <UploadOutlined />
                                        <br />
                                        Subir Imagenes
                                    </div>
                                    <ImagePreviewer
                                        src={this.state.imagePreview}

                                    />
                                </Uploader>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={20}>
                        <div className="mb-2">
                        <Divider style={{ marginBottom: "10px" }} >Descripciones</Divider>
                        <Text italic className="mb-2"><small>Debe de haber la misma cantidad de descripciones que imagenes, si se deja el campo vacio se utlilizará la descripción predeterminada para el evento, las descripciones se asignaran en el mismo orden de las imagenes que estan sobre esta sección</small></Text>
                            
                        </div>
                            <Form.List
                                name="descripciones"
                                label="Descripciones"
                                rules={[{
                                    validator: (_, value) => {
                                        console.log("value", value);
                                        console.log("other value", this.formRef.current.getFieldValue("imagenes_escritorio").length);

                                        if (value.length !== this.formRef.current.getFieldValue("imagenes_escritorio").length || value.length !== this.formRef.current.getFieldValue("imagenes_celular").length) {
                                            return Promise.reject("Debe de existir la misma cantidad de descripciones que imagenes");
                                        }

                                        return Promise.resolve();
                                    }
                                }]}
                            >

                                {
                                    (fields, { add, remove }, { errors }) => (
                                        <Form.Item>
                                            {fields.map((field, index) => (
                                                <Form.Item
                                                    required={false}
                                                    key={field.key}
                                                >
                                                    <Form.Item
                                                        {...field}
                                                        //validateTrigger={['onChange', 'onBlur']}

                                                        noStyle
                                                    >
                                                        <Input placeholder="Descripción" style={{ width: '90%' }} />
                                                    </Form.Item>
                                                    {fields.length > 1 ? (
                                                        <AiOutlineMinusCircle
                                                            className="ml-1"
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    ) : null}
                                                </Form.Item>

                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{ width: '100%' }}

                                                >
                                                    Agregar Descripción
                                                </Button>


                                            </Form.Item>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>

                                    )
                                }

                            </Form.List>
                        </Col>




                    </Row>

                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { open = false, onClose = () => { }, usuario } = props

    const formulario = useRef()

    const saveValues = () => {
        formulario.current.submit()
    }

    return <Drawer
        title={`${props.sorteo_id ? "Editar" : "Nuevo"} Sorteo`}
        placement={"bottom"}
        onClose={onClose}
        open={open}
        key={"bottom"}
        height={800}
        destroyOnClose={true}
        extra={
            <Space>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={saveValues} type="primary">
                    Aceptar
                </Button>
            </Space>
        }
    >
        <DrawerImagenes
            {...props}
            ref={formulario}
        />
    </Drawer>

}