import { Button, Col, Layout, Row, Spin, Typography, message, Image, Card } from 'antd'
import React, { Component, useEffect } from 'react'


import './ComoParticipar.scss'

const { Content } = Layout
const { Paragraph, Title, Text } = Typography



export default function (props) {

    const { background = true } = props

    useEffect(() => {

    }, [])

    return <section className={'como-participar ' + (background ? 'background' : '')} {...props}>
        {background && <img className='participar-bg top' src='/img/bg_1.svg' />}
        {background && <div className='participar-bg rect' />}
        {background && <img className='participar-bg bottom' src='/img/bg_2.svg' />}
        {background && <img className='lines' src='/img/line.svg' />}
        <div className='como-participar-container'>
            <div className='como-participar-header'>
                <Title className='como-participar-title'>¿Cómo puedes</Title>
                <Title className='como-participar-title'><span className='text-white' style={{ textShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)' }}>participar?</span></Title>
            </div>
            <div className='como-participar-content'>
                <div className='como-participar-cards'>
                    <Card
                        data-aos="fade-up"
                        // data-aos-delay={'100'}
                        size='small' className='participar-card'>
                        <img className='participar-card-image' src='/img/participar/tickets.svg' alt='Tickets' />
                        <Title className='participar-card-title' level={4}>Selecciona tus boletos</Title>
                        <Paragraph className='participar-card-description'>Selecciona tus boletos y hecha la suerte</Paragraph>
                    </Card>
                    <Card
                        data-aos="fade-up"
                        data-aos-delay={'200'}//"300"
                        size='small' className='participar-card'>
                        <img className='participar-card-image' src='/img/participar/buy.svg' alt='Comprar boletos' />
                        <Title className='participar-card-title' level={4}>Realiza tu pago</Title>
                        <Paragraph className='participar-card-description'>Contactanos por correo o whatsapp y realiza tu transferencia</Paragraph>
                    </Card>
                    <Card
                        data-aos="fade-up"
                        data-aos-delay={'400'}//"300"
                        size='small' className='participar-card'>
                        <img className='participar-card-image' src='/img/participar/party.svg' alt='Esperar el día del Evento' />
                        <Title className='participar-card-title' level={4}>¡Espera el día del Evento!</Title>
                        <Paragraph className='participar-card-description'>
                            Espera hasta el día del evento ¡Te compartiremos un link!
                        </Paragraph>
                    </Card>
                </div>
                <img data-aos="fade-up"
                    data-aos-delay={'600'} className='image-left' src='/img/carros_dobles.png' alt='Esperar el día del Evento' />
            </div>
        </div>

    </section>
}